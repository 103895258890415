import { ComponentType } from 'react'
import { Component } from '@/types'
import urlSliderTextBottomGenerator from './url-slider-text-bottom'
import creativeSliderOutfitSliderGenerator from './creative-slider-outfit-slider'
import bannerTextLinksGenerator from './banner-text-links'
import brandListingGenerator from './brand-listing'
import urlSliderTextOverlapGenerator from './url-slider-text-overlap'
import newsletterGenerator from './newsletter'
import creativeDetailOutfitGenerator from './creative-outfit-detail'
import creativeDetailProductGenerator from './creative-product-detail'
import BannerSliderTwoColumnsGenerator from './banner-slider-two-columns'
import urlSliderWithoutTextSmallerItemsGenerator from './url-slider-without-text-smaller-items'
import urlSliderSeparateButtonGenerator from './url-slider-separate-button'
import lastSeenGenerator from './last-seen'
import signUpGenerator from './sign-up'
import signInGenerator from './sign-in'
import profileGenerator from './profile'
import forgotPasswordGenerator from './forgot-password'
import resetPasswordGenerator from './reset-password'
import userVerificationGenerator from './user-verification'
import staticPageGenerator from './static-page'
import contactPageGenerator from './info--contact-us'
import InternalLinkGenerator from './internal-link'
import error404Generator from './error-404'
import error500Generator from './error-500'
import creativeListingProductGenerator from './creative-listing-product'
import creativeListingCombinationGenerator from './creative-listing--combination'
import combinationFilterGenerator from './combination--filter'
import creativeListingOutfitGenerator from './creative-listing--outfit'
import blogListingGenerator from './blog-listing'
import favoriteGenerator from './favorite'
import favoriteOutfitsGenerator from './favorite-outfits'
import favoriteProductsGenerator from './favorite-products'
import sitemap from './sitemap'
import staticTextGenerator from './static-text'
import creativeDetailParentProduct from './creative-detail--parent-product'
import infoBecomePartner from './info--become-partner'
import infoAdvertisingServices from './info--advertising-services'
import priceAlerts from './price-alerts'

export type Generators = {
  // eslint-disable-next-line
  [key in Component.TechnicalName]?: ComponentType<any>
}

const generators: Generators = {
  [Component.TechnicalName.URL_SLIDER_TEXT_BOTTOM]:
    urlSliderTextBottomGenerator,
  [Component.TechnicalName.CREATIVE_SLIDER_OUTFIT_SLIDER]:
    creativeSliderOutfitSliderGenerator,
  [Component.TechnicalName.BANNER_TEXT_LINKS]: bannerTextLinksGenerator,
  [Component.TechnicalName.BRAND_LISTING]: brandListingGenerator,
  [Component.TechnicalName.URL_SLIDER_TEXT_OVERLAP]:
    urlSliderTextOverlapGenerator,
  [Component.TechnicalName.NEWSLETTER]: newsletterGenerator,
  [Component.TechnicalName.CREATIVE_DETAIL_OUTFIT]:
    creativeDetailOutfitGenerator,
  [Component.TechnicalName.CREATIVE_DETAIL_PRODUCT]:
    creativeDetailProductGenerator,
  [Component.TechnicalName.BANNER_SLIDER_TWO_COLUMNS]:
    BannerSliderTwoColumnsGenerator,
  [Component.TechnicalName.URL_SLIDER_WITHOUT_TEXT_SMALLER_ITEMS]:
    urlSliderWithoutTextSmallerItemsGenerator,
  [Component.TechnicalName.URL_SLIDER_SEPARATE_BUTTON]:
    urlSliderSeparateButtonGenerator,
  [Component.TechnicalName.LAST_SEEN]: lastSeenGenerator,
  [Component.TechnicalName.SIGN_UP]: signUpGenerator,
  [Component.TechnicalName.SIGN_IN]: signInGenerator,
  [Component.TechnicalName.PROFILE]: profileGenerator,
  [Component.TechnicalName.USER_VERIFICATION]: userVerificationGenerator,
  [Component.TechnicalName.FORGOT_PASSWORD]: forgotPasswordGenerator,
  [Component.TechnicalName.RESET_PASSWORD]: resetPasswordGenerator,
  [Component.TechnicalName.ABOUT_US]: staticPageGenerator,
  [Component.TechnicalName.DATA_POLICY]: staticPageGenerator,
  [Component.TechnicalName.IMPRESSUM]: staticPageGenerator,
  [Component.TechnicalName.TERM_OF_USE]: staticPageGenerator,
  [Component.TechnicalName.FAQ]: staticPageGenerator,
  [Component.TechnicalName.CONTACT_US]: contactPageGenerator,
  [Component.TechnicalName.INTERNAL_LINK]: InternalLinkGenerator,
  [Component.TechnicalName.ERROR_404]: error404Generator,
  [Component.TechnicalName.ERROR_500]: error500Generator,
  [Component.TechnicalName.CREATIVE_LISTING_PRODUCT]:
    creativeListingProductGenerator,
  [Component.TechnicalName.CREATIVE_LISTING_COMBINATION]:
    creativeListingCombinationGenerator,
  [Component.TechnicalName.COMBINATION_FILTER]: combinationFilterGenerator,
  [Component.TechnicalName.CREATIVE_LISTING_OUTFIT]:
    creativeListingOutfitGenerator,
  [Component.TechnicalName.FAVORITE]: favoriteGenerator,
  [Component.TechnicalName.FAVORITE_OUTFITS]: favoriteOutfitsGenerator,
  [Component.TechnicalName.FAVORITE_PRODUCTS]: favoriteProductsGenerator,
  [Component.TechnicalName.SITEMAP]: sitemap,
  [Component.TechnicalName.STATIC_TEXT]: staticTextGenerator,
  [Component.TechnicalName.BLOG_LISTING]: blogListingGenerator,
  [Component.TechnicalName.CREATIVE_DETAIL_PARENT_PRODUCT]:
    creativeDetailParentProduct,
  [Component.TechnicalName.INFO_BECOME_PARTNER]: infoBecomePartner,
  [Component.TechnicalName.INFO_ADVERTISING_SERVICES]: infoAdvertisingServices,
  [Component.TechnicalName.PRICE_ALERTS]: priceAlerts,
}

export default generators
