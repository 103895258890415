import { NG_TRACKER_PAGE_VIEW } from '@/utils/http-request'
import { NG_PAGE_VIEW_ACTIVITY_STATIC_TYPE } from './constants'
import { usePathname, useSearchParams } from 'next/navigation'

interface RequestBody {
  url: string
  uuid: string
  activityType: string

  [key: string]: string // Allow any additional properties
}

type Props = {
  hostName: string
}

const buildSrc = (url: string): string => {
  const requestBody: RequestBody = {
    url: url,
    uuid: process?.env?.NEXT_PUBLIC_NG_PIXEL_UUID ?? '',
    activityType: NG_PAGE_VIEW_ACTIVITY_STATIC_TYPE,
  }

  const src = new URL(NG_TRACKER_PAGE_VIEW)
  Object.entries(requestBody)
    .filter(([, value]) => value !== undefined && value !== '')
    .forEach(([key, value]) => src.searchParams.set(key, value.toString()))

  return src.toString()
}

export default function NgStaticTracker({ hostName }: Props) {
  // Server-side static URL generation
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const search = searchParams.toString()
  const serverFullUrl = `${hostName}${pathname}${search ? '?' + search : ''}`

  const src = buildSrc(serverFullUrl)

  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      src={src}
      alt='pixel static'
      width='1'
      height='1'
      style={{ display: 'none' }}
      suppressHydrationWarning
    />
  )
}
