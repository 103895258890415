export const parsePrice = (price: string) => {
  return parseFloat(price?.replace(/[^\d,]/g, '')?.replace(',', '.'))
}

export const sortByStatus = (data: any) => {
  return data.sort((a: any, b: any) => {
    const statusA = a.status === '' ? -Infinity : a.status
    const statusB = b.status === '' ? -Infinity : b.status
    return statusA - statusB
  })
}

export const calculateDiscountedPrice = (
  productPrice: number,
  percentage: number
) => {
  return (productPrice * (1 - percentage / 100)).toFixed(2) // Ensure 2 decimal places
}
