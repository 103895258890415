import React from 'react'
import { Typography } from '@material-tailwind/react'
import IconSvg from '@/components/IconSvg'
import { PriceHistory } from '@/types'

const PriceStat = ({
  label,
  data,
}: {
  label: string
  data: PriceHistory.StatsItem
}) => {
  let arrowColor = 'text-gray-500'
  let icon = 'arrow-right'

  if (data.arrow === 'up') {
    arrowColor = 'text-green-500'
    icon = 'arrow-up-right'
  } else if (data.arrow === 'down') {
    arrowColor = 'text-red-500'
    icon = 'arrow-down-right'
  }

  return (
    <div className='grid grid-cols-2 grid-rows-[auto_auto] items-center gap-x-2 border-b py-2.5 last:border-0 md:grid-cols-3 md:grid-rows-[auto]'>
      <div className='row-span-2 text-left'>
        <Typography variant='h3' className='text-base font-medium text-primary'>
          {label}
        </Typography>
        <Typography variant='paragraph' className='text-base text-primary'>
          {data.info}
        </Typography>
      </div>
      <Typography
        as='p'
        variant='h3'
        className='text-right text-xl font-bold text-primary'
      >
        {data.price}
      </Typography>
      <Typography
        variant='paragraph'
        className={`flex items-center justify-end gap-1 text-left text-base ${arrowColor}`}
      >
        <IconSvg className='size-3 fill-current' icon={icon} />
        {data.difference}
      </Typography>
    </div>
  )
}

export default PriceStat
