import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import {
  Breadcrumb,
  ParentProductDetailComponent,
  PriceHistory,
  Properties as PropertiesType,
} from '@/types'
import Breadcrumbs from '@/components/Breadcrumbs'
import {
  ParentImageGallery,
  ParentProductInfo,
  ParentProductVariants,
  ParentProductOffers,
  ParentProductDetails,
  ParentProductPriceHistory,
  useFilteredAndSortedVariants,
  useFilteredOffers,
  filterAndSortVariants,
} from '@/components/ParentProductDetail'
import {
  GetComponentTrackingSpecificData,
  NG_WAS_PARENT_PRODUCT_DETAIL,
  NG_WAS_PARENT_PRODUCT_VARIANT_DETAIL,
} from '@/components/NgTracker'
import { useFilterProperties } from '@/components/ParentProductDetail/utils/filterProperties'
import { usePage } from '@/providers'

type Props = {
  breadcrumb: Array<Breadcrumb>
  product: ParentProductDetailComponent.ProductDetail
  variants: Array<ParentProductDetailComponent.ProductVariants>
  offers: Array<ParentProductDetailComponent.ProductOffers>
  details: ParentProductDetailComponent.ProductDetails
  history: PriceHistory.PriceHistory
  isVariantPage: boolean
}

export default function ProductDetail({
  breadcrumb,
  product,
  variants,
  offers,
  details,
  history,
  isVariantPage,
}: Props) {
  const { pages, cookies, channelShopUrlTags, channelId } = usePage()
  const cookieKey = `activeOptions_${product.parentProductId || product.id}`

  const [activeOptions, setActiveOptions] = useState<{ [key: string]: string }>(
    {}
  )
  const [activeOptionsLoading, setActiveOptionsLoading] =
    useState<boolean>(true)

  const handleDynamicCookieUpdate = (
    cookieKey: string,
    parsedCookieValue: any,
    product: any,
    fields: string[],
    setActiveOptions: (value: any) => void
  ) => {
    const updatedCookieValue = { ...parsedCookieValue }
    let hasUpdates = false

    fields?.forEach((field) => {
      const productField = product?.[field]

      if (
        productField?.length &&
        productField?.length === 1 &&
        field !== PropertiesType.Types.SIZE
      ) {
        //@TODO remove color select in variant page
        // if (productField[0]?.slug !== parsedCookieValue[field]) {
        //   updatedCookieValue[field] = productField[0]?.slug
        //   hasUpdates = true
        // }
      } else {
        const isValidSize = productField?.some(
          (item: any) => item?.slug === parsedCookieValue[field]
        )

        if (!isValidSize) {
          if (field in updatedCookieValue) {
            delete updatedCookieValue[field]
            hasUpdates = true
          }
        }
      }
    })

    if (hasUpdates) {
      Cookies.set(cookieKey, JSON.stringify(updatedCookieValue), {
        expires: 1,
      })

      setActiveOptions(updatedCookieValue)
    }

    return hasUpdates
  }

  useEffect(() => {
    const cookieValue = Cookies.get(cookieKey)

    if (isVariantPage) {
      const parsedCookieValue = cookieValue ? JSON.parse(cookieValue) : {}
      if (
        handleDynamicCookieUpdate(
          cookieKey,
          parsedCookieValue,
          product,
          [PropertiesType.Types.COLOR, PropertiesType.Types.SIZE],
          setActiveOptions
        )
      ) {
        setActiveOptionsLoading(false)
        return
      }
    }

    if (cookieValue) {
      const parsedCookieValue = JSON.parse(cookieValue)

      if (
        JSON.stringify(parsedCookieValue) !== JSON.stringify(cookies[cookieKey])
      ) {
        setActiveOptions(parsedCookieValue)
      } else {
        setActiveOptions(cookies[cookieKey])
      }
    }
    setActiveOptionsLoading(false)
  }, [cookieKey])

  const onPropertyChange = (property: { [key: string]: string }) => {
    const updatedOptions = { ...activeOptions }
    const key = Object.keys(property)[0]
    const value = property[key]

    if (updatedOptions[key]) {
      if (updatedOptions[key] === value) {
        delete updatedOptions[key]
      } else {
        updatedOptions[key] = value
      }
    } else {
      updatedOptions[key] = value
    }

    const { filteredVariants, notDisabledVariantsCount } =
      filterAndSortVariants(variants, updatedOptions)

    if (notDisabledVariantsCount === 1 && key !== PropertiesType.Types.SIZE) {
      const notDisabledVariant = filteredVariants.find(
        (variant) => !variant.disabled
      )

      if (notDisabledVariant) {
        window.open(
          `/${pages.parentProduct}/${notDisabledVariant.parentProductSlug}/${notDisabledVariant.nexgeonProductId}`,
          '_self' as string
        )
      }
    } else if (isVariantPage && key !== PropertiesType.Types.SIZE) {
      window.open(
        `/${pages.parentProduct}/${product.parentProductSlug}`,
        '_self' as string
      )
    } else {
      setActiveOptions(updatedOptions)
    }

    Cookies.set(cookieKey, JSON.stringify(updatedOptions), { expires: 1 })
  }

  const onResetSelectedOptions = () => {
    window.open(
      `/${pages.parentProduct}/${product.parentProductSlug}`,
      '_self' as string
    )
    Cookies.remove(cookieKey)
  }

  const { filteredVariants } = useFilteredAndSortedVariants(
    variants,
    activeOptions
  )

  const filteredOffers = useFilteredOffers(offers, activeOptions)

  const filteredProperties = useFilterProperties(
    product?.properties || [],
    filteredOffers,
    activeOptions
  )

  return (
    <section className='overflow-hidden'>
      <div className='container'>
        <Breadcrumbs breadcrumbs={breadcrumb} />

        <div
          className='grid w-full grid-cols-1 px-3 pb-3 pt-2 md:grid-cols-[30%_calc(70%-33px)] md:grid-rows-[auto_auto_1fr] md:gap-x-8 lg:grid-cols-[30%_calc(48%-65px)_22%] lg:grid-rows-[auto_1fr]'
          style={{
            backgroundColor: '#efefef',
          }}
        >
          <div
            className={`order-2 w-full bg-white md:order-1 md:row-span-3 md:mb-0 lg:row-span-2`}
          >
            <ParentImageGallery
              className={`${!isVariantPage ? '!hidden md:!block' : ''} `}
              medias={product.medias}
              alt={product.name}
            />
          </div>

          <ParentProductInfo
            name={product.name}
            subName={isVariantPage ? product?.nexgeonProductName : ''}
            properties={filteredProperties}
            productOverview={product.productOverview}
            similarProductUrls={product.similarProductUrls}
            onPropertyChange={onPropertyChange}
            variants={isVariantPage ? filteredVariants : []}
            resetSelectedOptions={onResetSelectedOptions}
            activeId={product?.nexgeonProductId}
            className='order-3'
            titleClassName='order-1 md:order-2'
          />

          <ParentProductPriceHistory
            productTitle={product.name}
            parentProductId={
              isVariantPage ? product.parentProductId : product.id
            }
            nexgeonProductId={product?.nexgeonProductId}
            history={history}
            className='order-2 border-b-2 border-t-2 border-white py-3 lg:row-span-2'
          />
        </div>
      </div>

      {!isVariantPage && (
        <ParentProductVariants className='mb-4' variants={filteredVariants} />
      )}

      <ParentProductOffers
        className='mb-8'
        offers={filteredOffers}
        componentTrackingSpecificData={GetComponentTrackingSpecificData(
          '',
          isVariantPage
            ? NG_WAS_PARENT_PRODUCT_VARIANT_DETAIL
            : NG_WAS_PARENT_PRODUCT_DETAIL,
          channelShopUrlTags,
          channelId
        )}
        loading={activeOptionsLoading}
      />

      <ParentProductDetails className='mb-4' details={details} />
    </section>
  )
}
