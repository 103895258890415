import { Properties as PropertiesType } from '@/types'
import FakeLink from '@/components/FakeLink'
import { usePage } from '@/providers'
import Icon from '@/components/Icon'
import { useState } from 'react'

type Props = {
  options: Array<PropertiesType.PropertyOptions>
  onChange?: (property: string) => void
  isActive?: string
}

export default function Color({ options, onChange, isActive = '' }: Props) {
  const { pages } = usePage()
  const [activeColor, setActiveColor] = useState<string>(isActive)

  const changeColor = (value: string) => {
    if (onChange) {
      onChange(value)
      setActiveColor((prevState) => {
        if (isActive === value && !prevState) {
          return ''
        } else {
          return prevState === value ? '' : value
        }
      })
    }
  }

  return (
    <div className='flex flex-wrap gap-3'>
      {options.map((option, index) => {
        const isDisabled = option?.disabled
        const isBlack = option.colorHex === '#000000'
        const isActiveItem = activeColor
          ? activeColor === option.slug
          : isActive === option.slug

        const commonClasses = `overflow-hidden relative flex items-center justify-center h-6 w-6 border ${
          isActiveItem && !isBlack ? 'border-black' : 'border-light-gray-color'
        } ${option.gradient ? `bg-gradient-${option.gradient}` : option.slug} ${
          isDisabled
            ? 'cursor-not-allowed opacity-50 border-gray-400'
            : 'cursor-pointer'
        }`

        const backgroundColorStyle = !option.gradient
          ? {
              backgroundColor: '#efefef', // Set background color to white
              border: `2px solid ${option.colorHex}`,
              width: 'auto',
              padding: '15px 8px',
              fontSize: '11px',
              color: '#000000',
            }
          : undefined

        return onChange ? (
          <div
            key={index}
            onClick={() => !isDisabled && changeColor(option.slug)}
            className={commonClasses}
            style={backgroundColorStyle}
          >
            {option.value}
            {!!isActiveItem && (
              <span
                className={`absolute left-0 top-0 flex h-[20px] w-[20px] -translate-x-1/2 -translate-y-1/2 rotate-45 ${isBlack ? 'bg-light-gray-color text-black' : 'bg-black text-white'} text-sm`}
              >
                <Icon
                  className={`absolute bottom-[6px] right-[0.5px] z-10 block -rotate-45 font-bold`}
                  icon='check'
                  size='8px'
                />
              </span>
            )}
          </div>
        ) : (
          <FakeLink
            key={index}
            href={`/${pages.products}/${option.slug}`}
            className={commonClasses}
            style={backgroundColorStyle}
          />
        )
      })}
    </div>
  )
}
