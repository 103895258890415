import { PriceHistory as PriceHistoryTypes } from '@/types'
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Tooltip,
  Typography,
} from '@material-tailwind/react'
import { useDictionary } from '@/providers'
import React, { useState, useCallback } from 'react'
import IconSvg from '@/components/IconSvg'

import PriceGraphDialog from './components/price-graph-dialog'
import PriceAlertDialog from './components/price-alert-dialog'
import PriceAlertDeleteDialog from './components/price-alert-delete-dialog'

import Link from '@/components/Link'
import htmlToText from '@/utils/html-to-text'
import Image from '@/components/Image'
import Alert from '@/components/Alert'

type Props = {
  productId: number
  productVariantId?: number | undefined
  history: PriceHistoryTypes.PriceHistory
  className?: string
  url: string
  id: number
  name: string
  mediaUrl: string
  mediaAlt: string
  mediaBucket: string | unknown
  mediaKey: string | unknown
  imageProps?: object
  currentPrice: string
  price: string
  priceReachedAt: string
  status: number
}

export function PriceHistoryCard({
  productId,
  productVariantId,
  history,
  id,
  name,
  mediaAlt,
  mediaUrl,
  mediaBucket,
  mediaKey,
  className = '',
  imageProps = {},
  currentPrice,
  price,
  status,
  priceReachedAt,
  url = '',
}: Props) {
  const d = useDictionary('price_history_and_alert_card')

  const [popupOpen, setPopupOpen] = useState({
    priceHistory: false,
    priceAlert: false,
    deleteAlert: false,
  })

  const togglePopup = useCallback(
    (popup: 'priceHistory' | 'priceAlert' | 'deleteAlert', state: boolean) => {
      setPopupOpen((prev) => ({ ...prev, [popup]: state }))
    },
    []
  )

  const inactive =
    status === PriceHistoryTypes.status.INACTIVE ||
    status === PriceHistoryTypes.status.PRICE_REACHED

  return (
    <>
      <Card
        color='transparent'
        shadow={false}
        className={`w-full max-w-[26rem] rounded-none ${className}`}
      >
        <div className='flex w-full'>
          <CardHeader
            floated={false}
            shadow={false}
            color='transparent'
            className='m-0 w-32 rounded-none sm:w-48'
          >
            <Link
              as='div'
              href={url}
              target='_blank'
              className='block h-32 sm:h-48'
              title={htmlToText(name)}
            >
              <Image
                data-key={mediaKey}
                data-bucket={mediaBucket}
                data-id={id}
                className='outfit-card-image absolute inset-x-0 inset-y-0 size-full object-contain object-center'
                src={mediaUrl}
                alt={htmlToText(mediaAlt)}
                width={200}
                height={200}
                {...imageProps}
              />
            </Link>
          </CardHeader>
          <CardBody className='relative flex-1 px-4 py-2 pr-10 sm:pr-14'>
            {name && (
              <Typography variant='h3'>
                <Link
                  as='span'
                  href={url}
                  target='_blank'
                  title={htmlToText(name)}
                  className='mb-2 !line-clamp-2 block overflow-hidden text-ellipsis text-left text-lg font-normal text-primary'
                  dangerouslySetInnerHTML={{ __html: name }}
                />
              </Typography>
            )}

            <Typography
              variant='paragraph'
              className='mb-2 text-left text-3xl font-medium text-black'
            >
              {currentPrice}
            </Typography>
            <div className='flex items-center gap-1'>
              <span className='text-sm text-primary'>
                {d('price_alert_card_price_title')}
              </span>
              <Typography
                variant='lead'
                className='text-sm font-medium text-primary md:text-right'
              >
                {price}
              </Typography>
            </div>
            {inactive && priceReachedAt && (
              <Alert state='info' className='mt-2 text-left'>
                <Typography variant='h4'>
                  {d('price_alert_price_reached_title')}
                </Typography>
                <span
                  dangerouslySetInnerHTML={{
                    __html: d('price_alert_price_reached_info', {
                      date: `${priceReachedAt}`,
                    }),
                  }}
                ></span>
              </Alert>
            )}
            <div className='absolute right-1 top-2'>
              {status !== PriceHistoryTypes.status.INACTIVE ? (
                <Tooltip
                  content={d('price_alert_notifications-on')}
                  placement='top'
                >
                  <span className='cursor-pointer'>
                    <IconSvg
                      className='size-8 fill-current sm:size-10'
                      icon='notifications-on'
                    />
                  </span>
                </Tooltip>
              ) : (
                <Tooltip
                  content={d('price_alert_notifications-off')}
                  placement='top'
                >
                  <span className='cursor-pointer'>
                    <IconSvg
                      className='size-8 fill-current sm:size-10'
                      icon='notifications-off'
                    />
                  </span>
                </Tooltip>
              )}
            </div>
          </CardBody>
        </div>
        <CardFooter className='grid grid-cols-3 border-t p-0 py-4 text-right sm:grid-cols-4'>
          <div
            onClick={() => togglePopup('priceHistory', true)}
            className='flex cursor-pointer flex-col items-center justify-center gap-2 border-r px-2 py-1 text-center text-xs sm:text-base'
          >
            <IconSvg className='size-4 fill-current sm:size-6' icon='graph' />
            {d('price_history_btn_title')}
          </div>
          <div
            onClick={() => togglePopup('priceAlert', true)}
            className='flex cursor-pointer flex-col items-center justify-center gap-2 border-r px-2 py-1 text-center text-xs sm:text-base'
          >
            <IconSvg className='sm:size-6fill-current size-4' icon='edit' />
            {inactive
              ? d('price_alarm_re_assign_btn_title')
              : d('price_alarm_edit_btn_title')}
          </div>

          <div
            onClick={() => togglePopup('deleteAlert', true)}
            className='flex cursor-pointer flex-col items-center justify-center gap-2 border-r px-2 py-1 text-center text-xs sm:text-base'
          >
            <IconSvg className='size-4 fill-current sm:size-6' icon='delete' />
            {d('price_alarm_delete_btn_title')}
          </div>

          <Link
            as='div'
            href={url}
            target='_blank'
            title={htmlToText(name)}
            className='col-span-3 m-2 mb-0 flex cursor-pointer items-center justify-center gap-2 bg-primary px-2 py-2.5 text-center text-md text-white sm:col-span-1 sm:m-0 sm:flex-col sm:bg-transparent sm:py-1 sm:text-base sm:text-primary'
          >
            <IconSvg className='size-4 fill-current sm:size-6' icon='details' />
            {d('price_alarm_details_btn_title')}
          </Link>
        </CardFooter>
      </Card>

      <PriceGraphDialog
        open={popupOpen.priceHistory}
        productTitle={name}
        productId={productId}
        productVariantId={productVariantId}
        handleOpen={() => togglePopup('priceHistory', false)}
        history={history?.priceHistory}
        activeRange={history?.dateRange}
        dateRanges={history?.dateRanges}
        stats={history.stats}
        currency={history.currency}
      />

      <PriceAlertDialog
        isCard={true}
        isActive={status === PriceHistoryTypes.status.ACTIVE}
        open={popupOpen.priceAlert}
        handleOpen={() => togglePopup('priceAlert', false)}
        currentMinPriceProduct={{
          ...history.currentMinPriceProduct,
          id: productId,
          variantId: productVariantId,
          name: name,
          currency: history.currency,
          priceAlert: {
            ...history?.currentMinPriceProduct?.priceAlert,
            id: id,
            value: price,
          },
        }}
      />

      <PriceAlertDeleteDialog
        open={popupOpen.deleteAlert}
        handleOpen={() => togglePopup('deleteAlert', false)}
        id={id}
        isCard={true}
      />
    </>
  )
}
