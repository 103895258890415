import { Properties as PropertiesType } from '@/types'
import Icon from '@/components/Icon'
import { useState } from 'react'

type Props = {
  options: Array<PropertiesType.PropertyOptions>
  onChange?: (property: string) => void
  isActive?: string
}

export default function Size({ options, onChange, isActive = '' }: Props) {
  const [activeSize, setActiveSize] = useState<string>(isActive)

  const handleClick = (option: PropertiesType.PropertyOptions) => {
    if (onChange) {
      onChange(option.slug)
      setActiveSize((prevState) => {
        if (isActive === option.slug && !prevState) {
          return ''
        } else {
          return prevState === option.slug ? '' : option.slug
        }
      })
    }
  }

  return (
    <div className='flex flex-wrap gap-3'>
      {options.map((option, index) => {
        const isActiveItem = activeSize
          ? activeSize === option.slug
          : isActive === option.slug
        return (
          <div
            key={index}
            onClick={() => handleClick(option)}
            className={`relative flex h-6 w-auto min-w-6 justify-center overflow-hidden border p-1 text-left text-sm font-normal text-primary ${
              isActiveItem ? 'border-black' : 'border-light-gray-color'
            } ${
              option?.disabled
                ? 'cursor-not-allowed border-gray-400 opacity-50'
                : 'cursor-pointer'
            }`}
          >
            {!!isActiveItem && (
              <span className='absolute left-0 top-0 flex h-[20px] w-[20px] -translate-x-1/2 -translate-y-1/2 rotate-45 bg-black text-sm'>
                <Icon
                  className='absolute bottom-[6px] right-[0.5px] z-10 block -rotate-45 font-bold text-white'
                  icon='check'
                  size='8px'
                />
              </span>
            )}
            {option.value}
          </div>
        )
      })}
    </div>
  )
}
