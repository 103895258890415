'use client'

import { useState, useEffect, MouseEvent } from 'react'
import { Typography, Button } from '@material-tailwind/react'
import { Brand } from '@/types'
import { fetchBrands } from '@/actions/brand'
import { useDebounce, useParams } from '@/hooks'
import { usePage, useDictionary } from '@/providers'
// import WishlistButton from '@/components/WishlistButton'
import SearchInput from '@/components/SearchInput'
import Icon from '@/components/Icon'
import Link from '@/components/Link'
import Alert from '@/components/Alert'
import Skeleton from './Skeleton'
import { usePathname, useRouter } from 'next/navigation'

type Props = {
  count: number
  brands: Brand.Brands
  symbols: Array<string>
  className?: string
}

export default function BrandListing({
  count,
  brands,
  symbols = [],
  className = '',
}: Props) {
  const { pages } = usePage()
  const d = useDictionary('brand')
  const { search: searchParam = '', symbol: symbolParam = '' } = useParams()

  const LIMIT = 16
  const router = useRouter()
  const pathname = usePathname()
  const [list, setList] = useState<Brand.Brands>(brands)
  const [total, setTotal] = useState<number>(count)
  const [searchValue, setSearch] = useState<string>(searchParam)
  const search = useDebounce<string>(searchValue)
  const [symbol, setSymbol] = useState<string>(symbolParam)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingMore, setLoadingMore] = useState<string>('')
  const [initialPageLoading, setInitialPageLoading] = useState<boolean>(
    !!(searchParam || symbolParam)
  )

  const getBrands = async (searchVal: string, symbolVal: string) => {
    setInitialPageLoading(true)

    if (!initialPageLoading) {
      return
    }
    setLoading(true)

    try {
      const { data } = await fetchBrands(symbolVal, searchVal)

      setList(data.brands)
      setTotal(data.count)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      /* empty */
    }
    setLoading(false)
  }

  const onShowMoreBrands = async (
    event: MouseEvent<HTMLButtonElement>,
    symbolVal: string
  ) => {
    event.preventDefault()

    setLoadingMore(symbolVal)

    try {
      const { data } = await fetchBrands(symbolVal, search)

      const newList = { ...list }
      newList[symbolVal] = data.brands[symbolVal]

      setList(newList)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      /* empty */
    }
    setLoadingMore('')
  }

  const changeUrlParams = (searchVal: string, symbolVal: string) => {
    const queryParams: Record<string, string> = {}

    if (searchVal) {
      queryParams.search = searchVal
    }
    if (symbolVal) {
      queryParams.symbol = symbolVal
    }

    const searchParams = new URLSearchParams(queryParams)
    const query = searchParams.toString()

    router.push(`${pathname}${query.length ? `?${query}` : ''}`)
  }

  const getSymbolTitle = (key: string): string => {
    if (key === '') {
      return d('symbol_all')
    }
    if (key === 'rest') {
      return d('symbol_rest')
    }
    return key
  }

  useEffect(() => {
    changeUrlParams(search, symbol)
    getBrands(search, symbol)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, symbol])

  const List = () => {
    if (loading) {
      return <Skeleton />
    }

    if (total < 1) {
      return <Alert state='warning'>{d('search_not_found_message')}</Alert>
    }

    return Object.keys(list).map((brandKey: string) => (
      <div key={brandKey} className='flex flex-col gap-6'>
        <Typography
          as='p'
          variant='h2'
          className='text-left font-medium text-primary underline underline-offset-2'
        >
          {d('pretext')}{' '}
          <span className='uppercase'>{getSymbolTitle(brandKey)}</span>
        </Typography>

        <div className='grid grid-cols-1 gap-x-16 gap-y-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
          {list[brandKey].map((brand: Brand.Brand, index: number) => (
            <div key={index} className='flex flex-row items-center gap-3'>
              <Link
                href={`/${pages.brand}/${brand.slug}`}
                title={brand.value}
                className='flex items-center justify-between text-left text-base font-medium text-primary'
              >
                <span className='mr-3'>{brand.value}</span>
                <Icon icon='brand' className='text-2xl' />
              </Link>
              <Link
                href={`/${pages.combinations}/${brand.slug}`}
                title={brand.value}
                className='flex items-center text-left text-base font-medium text-primary'
              >
                <Icon icon='kombinieren' className='text-2xl' />
              </Link>

              {/*
               @TODO This part is still needed in the future
               <WishlistButton
                  id={brand.id}
                  isIconOnly={false}
                  type='brand'
                  className='ml-auto flex-initial shadow-none hover:shadow-none focus:shadow-none'
                />*/}
            </div>
          ))}
        </div>

        {list[brandKey].length === LIMIT && (
          <div className='flex justify-end'>
            <Button
              aria-label={d('show_all_label')}
              variant='text'
              disabled={loadingMore !== ''}
              loading={loadingMore === brandKey}
              onClick={(event: MouseEvent<HTMLButtonElement>) =>
                onShowMoreBrands(event, brandKey)
              }
              className='flex cursor-pointer items-center gap-2 p-2 text-left text-base font-medium uppercase text-primary hover:bg-primary hover:text-white active:bg-primary active:text-white'
            >
              {d('show_all_label')} <b>{getSymbolTitle(brandKey)}</b>
              <Icon className='mb-px block' icon='arrow-right3' size='18px' />
            </Button>
          </div>
        )}
      </div>
    ))
  }

  return (
    <div className={`flex flex-col gap-6 ${className}`}>
      <Typography
        variant='h1'
        className='text-left text-2xl text-primary underline underline-offset-2'
      >
        {d('title')}
      </Typography>

      <div className='flex flex-wrap gap-1'>
        {['', ...symbols].map((el, index) => (
          <Button
            variant='text'
            key={index}
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
              event.preventDefault()
              setSymbol(el)
            }}
            className={`cursor-pointer p-2 text-left text-base font-medium uppercase text-primary hover:bg-primary hover:text-white active:bg-primary active:text-white ${
              symbol === el ? 'bg-primary text-white' : ''
            }`}
          >
            {getSymbolTitle(el)}
          </Button>
        ))}
      </div>

      <SearchInput
        onChange={(value: string) => {
          setSearch(value)
        }}
        value={searchParam}
      />

      <div className='flex flex-col gap-16'>
        <List />
      </div>
    </div>
  )
}
