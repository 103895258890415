'use client'

import React, { useState } from 'react'
import Icon from '@/components/Icon'

type Props = {
  totalItemCount: number
  activePage?: number
  itemsPerPage?: number
}

export default function Pagination({
  totalItemCount,
  activePage = 1,
  itemsPerPage = 8,
}: Props) {
  const [activeItem, setActiveItem] = useState(activePage)

  const totalPages = Math.ceil(totalItemCount / itemsPerPage)
  const maxDisplayedPages = 5

  const updateURL = (page: number) => {
    const pathSegments = window.location.pathname.split('/')
    const basePath = pathSegments.slice(0, 2).join('/')
    let slug = pathSegments.slice(2).join('/')

    if (!Number.isNaN(parseInt(pathSegments[2]))) {
      slug = pathSegments.slice(3).join('/')
    }

    const searchParams = window.location.search // Get existing query params
    const newURL = `${basePath}/${page}/${slug}${searchParams}` // Preserve query params

    window.open(newURL, '_self' as string)
  }

  const handlePrevClick = () => {
    if (activeItem > 1) {
      const newPage = activeItem - 1
      setActiveItem(newPage)
      updateURL(newPage)
    }
  }

  const handleNextClick = () => {
    if (activeItem < totalPages) {
      const newPage = activeItem + 1
      setActiveItem(newPage)
      updateURL(newPage)
    }
  }

  const handlePageClick = (pageNumber: number) => {
    setActiveItem(pageNumber)
    updateURL(pageNumber)
  }

  const renderPageNumbers = () => {
    let startPage = Math.max(2, activeItem - Math.floor(maxDisplayedPages / 2))
    const endPage = Math.min(totalPages - 1, startPage + maxDisplayedPages - 1)

    if (endPage - startPage < maxDisplayedPages - 1) {
      startPage = Math.max(2, endPage - maxDisplayedPages + 1)
    }

    const pages = []

    if (startPage > 2) {
      pages.push(
        <span
          key='start-ellipsis'
          className='ellipsis flex h-8 w-8 select-none items-center justify-center border border-light-silver-color text-xs font-medium text-gray-900'
        >
          ...
        </span>
      )
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          className={`relative h-8 w-8 select-none text-center align-middle text-xs font-medium uppercase ${
            activeItem === i
              ? 'bg-gray-900 text-white'
              : 'border border-light-silver-color text-gray-900'
          } transition-all hover:opacity-75 focus:ring focus:ring-gray-300 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none`}
          type='button'
          onClick={() => handlePageClick(i)}
        >
          <span className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform'>
            {i}
          </span>
        </button>
      )
    }

    if (endPage < totalPages - 1) {
      pages.push(
        <span
          key='end-ellipsis'
          className='ellipsis flex h-8 w-8 select-none items-center justify-center border border-light-silver-color text-xs font-medium text-gray-900'
        >
          ...
        </span>
      )
    }

    return pages
  }

  return (
    <div className='flex w-full justify-center py-2.5'>
      <div className='row flex gap-2'>
        <button
          className='relative h-8 w-8 select-none text-center align-middle text-xs font-medium uppercase text-gray-900 transition-all hover:opacity-75 focus:ring focus:ring-gray-300 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
          type='button'
          onClick={handlePrevClick}
          disabled={activeItem === 1}
          aria-label='Previous page'
        >
          <span className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform'>
            <Icon className='block text-black' icon='arrow-left' size='10px' />
          </span>
        </button>

        <button
          className={`relative h-8 w-8 select-none text-center align-middle text-xs font-medium uppercase ${
            activeItem === 1
              ? 'bg-gray-900 text-white'
              : 'border border-light-silver-color text-gray-900'
          } transition-all hover:opacity-75 focus:ring focus:ring-gray-300 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none`}
          type='button'
          onClick={() => handlePageClick(1)}
        >
          <span className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform'>
            1
          </span>
        </button>

        {renderPageNumbers()}

        {totalPages > 1 && (
          <button
            className={`relative h-8 w-8 select-none text-center align-middle text-xs font-medium uppercase ${
              activeItem === totalPages
                ? 'bg-gray-900 text-white'
                : 'border border-light-silver-color text-gray-900'
            } transition-all hover:opacity-75 focus:ring focus:ring-gray-300 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none`}
            type='button'
            onClick={() => handlePageClick(totalPages)}
          >
            <span className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform'>
              {totalPages}
            </span>
          </button>
        )}

        <button
          className='relative h-8 w-8 select-none text-center align-middle text-xs font-medium uppercase text-gray-900 transition-all hover:opacity-75 focus:ring focus:ring-gray-300 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
          type='button'
          onClick={handleNextClick}
          disabled={activeItem === totalPages}
          aria-label='Next page'
        >
          <span className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform'>
            <Icon className='block text-black' icon='arrow-right' size='10px' />
          </span>
        </button>
      </div>
    </div>
  )
}
