export type PropertyOptions = {
  id: number
  value: string
  slug: string
  colorHex: string
  gradient: string
  isActive?: boolean
  disabled?: boolean
}

export type Properties = {
  title: string
  name: Types
  values: Array<PropertyOptions>
  active?: string | null
}

export enum Types {
  COLOR = 'generated_color',
  SIZE = 'size',
}
