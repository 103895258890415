import { Typography } from '@material-tailwind/react'
import Slider, { SliderItem, breakpoints } from '@/components/Slider'
import OutfitCard from '@/components/OutfitCard'
import { Outfit } from '@/types/Outfit'
import { Thumbnails, PictureSources, Media } from '@/types'
import Link from '@/components/Link'

type Props = {
  title: string
  sliderItems: Array<Outfit>
  titleUrl?: string
  className?: string
  isFullWidth?: boolean
}

export default function OutfitSlider({
  title,
  sliderItems = [],
  className = '',
  titleUrl = '',
  isFullWidth,
}: Props) {
  const sliderProps = {
    slidesPerView: 1,
    spaceBetween: 20,
    grabCursor: true,
    nested: true,
    noSwipingSelector:
      '.card-outfit-similar-product-slider, .card-outfit-variant-slider',
    breakpoints: {
      [breakpoints.sm]: {
        slidesPerView: 2.2,
      },
      [breakpoints.md]: {
        slidesPerView: 3.3,
      },
      [breakpoints.lg]: {
        slidesPerView: 4.4,
      },
      [breakpoints.xl]: {
        slidesPerView: 4.4,
      },
      [breakpoints.xxl]: {
        slidesPerView: 4,
      },
    },
  }

  const buildMediaUrl = (media?: Media): string => {
    if (media === undefined) {
      return ''
    }

    if (
      media.thumbnails === undefined ||
      !('490x490' in media.thumbnails) ||
      !('330x330' in media.thumbnails)
    ) {
      return media.imageUrl
    }

    return media.thumbnails['330x330']
  }

  const buildMediaSources = (
    thumbnails?: Thumbnails
  ): PictureSources | undefined => {
    if (
      thumbnails === undefined ||
      !('490x490' in thumbnails) ||
      !('330x330' in thumbnails)
    ) {
      return
    }

    return {
      '479': {
        src: thumbnails['490x490'],
        size: 490,
      },
    }
  }

  return (
    <section className={`overflow-hidden py-8 md:py-16 ${className}`}>
      <div className={isFullWidth ? '' : 'container'}>
        <Typography
          as='p'
          variant='h2'
          className='outfit-slider-headline mb-6 text-left text-3xl font-semibold text-primary underline underline-offset-2'
        >
          <Link as='span' href={titleUrl} aria-label={title} title={title}>
            {title}
          </Link>
        </Typography>
        <Slider
          className='!overflow-visible'
          imageEl='.outfit-card-image'
          navNextClassName='sm:right-[9.2%] 2xl:right-0'
          {...sliderProps}
        >
          {sliderItems?.map((sliderItem, index) => (
            <SliderItem key={index}>
              <OutfitCard
                id={sliderItem.id}
                slug={sliderItem.slug}
                title={sliderItem.name}
                mediaUrl={buildMediaUrl(sliderItem.media)}
                mediaAlt={sliderItem.generatedDescription ?? sliderItem.name}
                mediaBucket={sliderItem.media?.bucket}
                mediaKey={sliderItem.media?.key}
                subType={sliderItem.subType}
                products={sliderItem.products}
                badges={sliderItem?.badges}
                favorite={sliderItem.favorite}
                imageProps={{
                  sources: buildMediaSources(sliderItem.media?.thumbnails),
                }}
              />
            </SliderItem>
          ))}
        </Slider>
      </div>
    </section>
  )
}
