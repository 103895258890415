import { memo } from 'react'
import dynamic from 'next/dynamic'
import type { ApexOptions } from 'apexcharts'

// Dynamically import ApexCharts to prevent SSR issues
const ApexChart = dynamic(() => import('react-apexcharts'), { ssr: false })

interface ChartProps {
  options: ApexOptions
  series: ApexOptions['series']
  type?:
    | 'line'
    | 'bar'
    | 'area'
    | 'pie'
    | 'donut'
    | 'radar'
    | 'scatter'
    | 'heatmap'
  width?: string | number
  height?: string | number
}

const Chart: React.FC<ChartProps> = ({
  options,
  series,
  type = 'line',
  width = '100%',
  height = 350,
}) => {
  return (
    <div className='apex-chart-container'>
      <ApexChart
        options={options}
        series={series}
        type={type}
        width={width}
        height={height}
      />
    </div>
  )
}

export default memo(Chart)
