export * as Yup from 'yup'
export { useForm } from 'react-hook-form'
export { yupResolver } from '@hookform/resolvers/yup'

export { default } from './form-provider'

export { default as RHFTextField } from './rhf-text-field'
export { default as RHFTextarea } from './rhf-textarea'
export { default as RHFPasswordField } from './rhf-password-field'
export { default as RHFRadioGroup } from './rhf-radio-group'

export const PASSWORD_REG_EXP =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d@$!%*#?&~`^|'")(-_=+}{}]{8,}$/
