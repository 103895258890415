import { Media } from '@/types'

export type FiltersItem = {
  status: string | number
  title: string
  count: number
}

export type Filters = Array<FiltersItem>

export type Card = {
  value: string
  media: Media
  name: string
  id: number
  parentProductId: number
  nexgeonProductId: number
  history: PriceHistory
  pageUrl: string
  priceReachedAt: string
  status: number
}

export type ApiResponsePriceAlert = PriceAlarm & {
  success: boolean
  errors: Array<string>
}

export type DateRange = {
  name: string
  shortName: string
}

export type HistoryItem = {
  price: string
  date: string
  dateFormatted: string
}

export type StatsItem = {
  price: string
  info: string
  difference: string
  arrow: 'up' | 'down'
}

export type Stats = {
  min: StatsItem
  max: StatsItem
  avg: StatsItem
}

export type DateRanges = Record<string, DateRange>

export type PriceAlarm = {
  id: number
  value: string
  media?: Media
  name?: string
  email?: string
  active?: boolean
}

export type CurrentMinPriceProduct = {
  price: string
  media: Media
  id: number
  variantId: number | undefined
  name: string
  currency: string
  priceAlert?: PriceAlarm | undefined
}

export type PriceHistory = {
  dateRange: number
  dateRanges: DateRanges
  priceHistory: HistoryItem[]
  stats: Stats
  currency: string
  currentMinPriceProduct: CurrentMinPriceProduct
}

export enum status {
  ACTIVE = 1,
  INACTIVE = 2,
  PRICE_REACHED = 4,
}
