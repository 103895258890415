import { UrlSliderComponent } from '@/types'
import BaseSlider from '@/containers/sections/base-slider'

export default function generate({
  component,
}: {
  component: UrlSliderComponent.Component
}) {
  return (
    <BaseSlider
      title={component.config.title}
      titleUrl={component.config.headerLink}
      sliderItems={component.config.urls}
      className={component.config.customClassName}
    />
  )
}
