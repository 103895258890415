import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
} from '@material-tailwind/react'
import React from 'react'

export const PriceHistoryCardSkeleton = ({
  className = '',
}: {
  className?: string
}) => {
  return (
    <Card
      color='transparent'
      shadow={false}
      className={`w-full max-w-[26rem] animate-pulse rounded-none ${className}`}
    >
      <div className='flex w-full'>
        <CardHeader
          floated={false}
          shadow={false}
          color='transparent'
          className='m-0 w-32 rounded-none sm:w-48'
        >
          <div className='grid h-32 place-items-center bg-gray-300 sm:h-48'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={2}
              stroke='currentColor'
              className='h-12 w-12 text-gray-500'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z'
              />
            </svg>
          </div>
        </CardHeader>
        <CardBody className='relative flex-1 px-4 py-2 pr-10 sm:pr-14'>
          <Typography
            as='div'
            variant='h1'
            className='mb-4 h-3 w-56 rounded-full bg-gray-300'
          >
            &nbsp;
          </Typography>

          <Typography
            as='div'
            variant='paragraph'
            className='mb-2 h-2 w-24 rounded-full bg-gray-300'
          >
            &nbsp;
          </Typography>
          <div className='flex items-center gap-1'>
            <Typography
              as='div'
              variant='paragraph'
              className='mb-2 h-2 w-24 rounded-full bg-gray-300'
            >
              &nbsp;
            </Typography>
            <Typography
              as='div'
              variant='paragraph'
              className='mb-2 h-2 w-24 rounded-full bg-gray-300'
            >
              &nbsp;
            </Typography>
          </div>
          <div className='absolute right-1 top-2'>
            <Typography
              as='div'
              variant='paragraph'
              className='mb-2 size-8 bg-gray-300 sm:size-10 sm:size-6'
            >
              &nbsp;
            </Typography>
          </div>
        </CardBody>
      </div>
      <CardFooter className='grid grid-cols-3 border-t p-0 py-4 text-right sm:grid-cols-4'>
        <div className='flex cursor-pointer flex-col items-center justify-center gap-2 border-r px-2 py-1 text-center text-xs sm:text-base'>
          <Typography
            as='div'
            variant='paragraph'
            className='mb-2 size-4 bg-gray-300 sm:size-6'
          >
            &nbsp;
          </Typography>
          <Typography
            as='div'
            variant='paragraph'
            className='mb-2 h-2 w-12 rounded-full bg-gray-300'
          >
            &nbsp;
          </Typography>
        </div>
        <div className='flex cursor-pointer flex-col items-center justify-center gap-2 border-r px-2 py-1 text-center text-xs sm:text-base'>
          <Typography
            as='div'
            variant='paragraph'
            className='mb-2 size-4 bg-gray-300 sm:size-6'
          >
            &nbsp;
          </Typography>
          <Typography
            as='div'
            variant='paragraph'
            className='mb-2 h-2 w-12 rounded-full bg-gray-300'
          >
            &nbsp;
          </Typography>
        </div>

        <div className='flex cursor-pointer flex-col items-center justify-center gap-2 border-r px-2 py-1 text-center text-xs sm:text-base'>
          <Typography
            as='div'
            variant='paragraph'
            className='mb-2 size-4 bg-gray-300 sm:size-6'
          >
            &nbsp;
          </Typography>
          <Typography
            as='div'
            variant='paragraph'
            className='mb-2 h-2 w-12 rounded-full bg-gray-300'
          >
            &nbsp;
          </Typography>
        </div>
        <div className='col-span-3 m-2 mb-0 flex cursor-pointer items-center justify-center gap-2 bg-primary px-2 py-2.5 text-center text-md text-white sm:col-span-1 sm:m-0 sm:flex-col sm:bg-transparent sm:py-1 sm:text-base sm:text-primary'>
          <Typography
            as='div'
            variant='paragraph'
            className='mb-2 size-4 bg-gray-300 sm:size-6'
          >
            &nbsp;
          </Typography>
          <Typography
            as='div'
            variant='paragraph'
            className='mb-2 h-2 w-12 rounded-full bg-gray-300'
          >
            &nbsp;
          </Typography>
        </div>
      </CardFooter>
    </Card>
  )
}
