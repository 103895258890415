import { ParentProductDetailComponent, Properties } from '@/types'
import { Typography } from '@material-tailwind/react'
import { useDictionary } from '@/providers'
import ProductProperties from '@/components/ProductProperties'
import { ParentProductVariantsSlider } from '@/components/ParentProductDetail/ParentProductVariantsSlider'
import React from 'react'

type Props = {
  name: string
  properties: Array<Properties.Properties>
  productOverview: string[]
  variants: Array<ParentProductDetailComponent.ProductVariants>
  onPropertyChange?: (property: { [key: string]: string }) => void
  resetSelectedOptions?: () => void
  subName?: string
  className?: string
  titleClassName?: string
  activeId?: number
  similarProductUrls?: Array<ParentProductDetailComponent.SimilarProductUrlItem>
}

export function ParentProductInfo({
  name,
  properties,
  productOverview,
  onPropertyChange,
  variants,
  resetSelectedOptions,
  activeId,
  subName = '',
  className = '',
  titleClassName = '',
  similarProductUrls = [],
}: Props) {
  const d = useDictionary('parent_product_detail')

  return (
    <>
      <Typography
        as='h1'
        variant='h1'
        className={`text-left text-2xl font-normal text-primary lg:mb-4 ${titleClassName}`}
      >
        <span>{name}</span>
        {!!subName && <span className='ml-1 break-all text-md'>{subName}</span>}
      </Typography>
      <div className={`flex flex-col ${className}`}>
        {!!(productOverview && productOverview.length) && (
          <Typography
            as='p'
            variant='h3'
            className='no-scrollbar mt-2 inline-flex flex-nowrap gap-1 overflow-y-hidden overflow-x-scroll text-left text-md text-primary sm:flex-wrap'
          >
            <span className='mb-0.5 inline-block whitespace-nowrap font-semibold lg:mb-0 lg:mr-0.5'>
              {d('properties_list')}
            </span>
            {productOverview.map((item, idx) => (
              <span key={idx} className='inline-block whitespace-nowrap'>
                {item.trim()}
                {idx < productOverview.length - 1 && ', '}
              </span>
            ))}
            <a
              className='hidden whitespace-nowrap font-bold text-black lg:inline-flex'
              href={`#product_details`}
              onClick={(e) => {
                e.preventDefault()
                document.getElementById('product_details')?.scrollIntoView({
                  behavior: 'smooth',
                })
              }}
            >
              {' '}
              {d('properties_list_anchor')}
            </a>
          </Typography>
        )}

        {!!(similarProductUrls && similarProductUrls.length) && (
          <Typography
            as='p'
            variant='h3'
            className='no-scrollbar mt-2 inline-flex flex-nowrap gap-1 overflow-y-hidden overflow-x-scroll text-left text-md text-primary sm:flex-wrap'
          >
            <span className='mb-0.5 inline-block whitespace-nowrap font-semibold lg:mb-0 lg:mr-0.5'>
              {d('similar_products')}
            </span>
            {similarProductUrls.map((item, idx) => (
              <a
                href={item.url}
                title={item.title.trim()}
                key={idx}
                className='inline-block whitespace-nowrap hover:underline'
              >
                {item.title.trim()}
                {idx < productOverview.length - 1 && ', '}
              </a>
            ))}
          </Typography>
        )}

        <ParentProductVariantsSlider
          variants={variants}
          resetSelectedOptions={resetSelectedOptions}
          activeId={activeId}
        />

        <ProductProperties
          properties={properties}
          onChange={onPropertyChange}
          className=''
        />
      </div>
    </>
  )
}
