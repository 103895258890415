import { Product } from '@/types'
import ProductCard from '@/components/ProductCard'
import InspirationCard from '@/components/InspirationCard'
import { TrackingExternalUrlSpecificData } from '@/components/NgTracker'

type Props = {
  variants: Array<Product.Product>
  isInspirationOutfit: boolean
  componentTrackingSpecificData?: TrackingExternalUrlSpecificData | undefined
}

export function OutfitVariants({
  variants,
  isInspirationOutfit,
  componentTrackingSpecificData,
}: Props) {
  return variants?.map((product, index) =>
    isInspirationOutfit ? (
      <InspirationCard
        key={index}
        id={product.id}
        href={product.link}
        slug={product.slug}
        mediaUrl={product.media?.imageUrl}
        mediaAlt={product.generatedDescription ?? product.name}
        mediaBucket={product.media?.bucket}
        mediaKey={product.media?.key}
        title={product.name}
        brandName={product?.brand?.value}
        badges={product.badges}
        showSimilar
        className='max-w-full border border-transparent hover:border-light-silver-color'
      />
    ) : (
      <ProductCard
        key={index}
        id={product.id}
        href={product.link}
        slug={product.slug}
        parentUrl={product.parentProduct?.url}
        parentOfferCount={product.parentProduct?.offerCount}
        mediaUrl={product.media?.imageUrl}
        mediaAlt={product.generatedDescription ?? product.name}
        mediaBucket={product.media?.bucket}
        mediaKey={product.media?.key}
        title={product.name}
        brandName={product?.brand?.value}
        price={product.price}
        generatedDescription={product.generatedDescription}
        shop={product.shop}
        hasSale={product.hasSale}
        previousPrice={product.previousPrice}
        available={product.available}
        badges={product.badges}
        shopId={product.shopId}
        showSimilar
        favorite={product.favorite}
        className='max-w-full border border-transparent hover:border-light-silver-color'
        componentTrackingSpecificData={componentTrackingSpecificData}
      />
    )
  )
}
