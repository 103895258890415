import { PriceAlertsComponent } from '@/types'
import PriceAlertsListing from '@/containers/sections/price-alerts-listing'

export default function generate({
  component,
}: {
  component: PriceAlertsComponent.Component
}) {
  const {
    priceAlerts,
    priceAlertCounts,
    count,
    limit,
    page,
    displayPagination,
    breadcrumb,
  } = component.config

  return (
    <PriceAlertsListing
      priceAlerts={priceAlerts}
      priceAlertsFilters={priceAlertCounts}
      count={count}
      limit={limit}
      page={page}
      displayPagination={displayPagination}
      breadcrumb={breadcrumb}
    />
  )
}
