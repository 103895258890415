import { PriceHistory as PriceHistoryTypes } from '@/types'
import { useDictionary } from '@/providers'
import { PriceHistory } from '@/components/PriceHistory'

type Props = {
  productTitle: string
  parentProductId: number
  nexgeonProductId?: number
  history: PriceHistoryTypes.PriceHistory
  className?: string
}

export function ParentProductPriceHistory({
  history,
  nexgeonProductId,
  parentProductId,
  productTitle,
  className,
}: Props) {
  const d = useDictionary('parent_product_detail')

  return (
    <PriceHistory
      history={history}
      className={className}
      productId={parentProductId}
      productVariantId={nexgeonProductId}
      productTitle={productTitle}
      anchorLink={{
        id: 'product_details',
        icon: 'details',
        text: d('properties_list_anchor'),
      }}
    />
  )
}
