'use client'

import { Fragment, useState, useTransition } from 'react'
import { preload } from 'react-dom'
import {
  PriceHistory,
  Breadcrumb,
  Thumbnails,
  PictureSources,
  Media,
} from '@/types'
import { useDictionary, usePage } from '@/providers'
import Breadcrumbs from '@/components/Breadcrumbs'
import {
  PriceHistoryCard,
  PriceHistoryCardSkeleton,
} from '@/components/PriceHistory'
import Pagination from '@/components/Pagination'
import { Button, ButtonGroup, Typography } from '@material-tailwind/react'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'

type Props = {
  priceAlerts: Array<PriceHistory.Card>
  priceAlertsFilters: PriceHistory.Filters
  count: number
  limit: number
  displayPagination: boolean
  breadcrumb: Array<Breadcrumb>
  page?: number
}

export default function PriceAlertsListing({
  priceAlerts,
  priceAlertsFilters,
  count,
  limit,
  page,
  displayPagination,
  breadcrumb,
}: Props) {
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const d = useDictionary('price_alerts_listing')
  const { metaInformation } = usePage()
  const initialStatus = Number(searchParams.get('status')) || ''
  const [activeFilter, setActiveFilter] = useState<string | number>(
    initialStatus
  )

  const [isPending, startTransition] = useTransition()

  const buildMediaUrl = (media?: Media): string => {
    if (media === undefined) {
      return ''
    }

    if (
      media.thumbnails === undefined ||
      !('750x750' in media.thumbnails) ||
      !('490x490' in media.thumbnails) ||
      !('330x330' in media.thumbnails)
    ) {
      return media.imageUrl
    }

    return media.thumbnails['330x330'] as string
  }

  const buildMediaSources = (
    thumbnails?: Thumbnails
  ): PictureSources | undefined => {
    if (
      thumbnails === undefined ||
      !('250x250' in thumbnails) ||
      !('200x200' in thumbnails) ||
      !('150x150' in thumbnails)
    ) {
      return
    }

    return {
      '420': {
        src: thumbnails['150x150'],
        size: 150,
      },
      '520': {
        src: thumbnails['200x200'],
        size: 200,
      },
      '767': {
        src: thumbnails['250x250'],
        size: 250,
      },
      '1023': {
        src: thumbnails['250x250'],
        size: 250,
      },
    }
  }

  if (priceAlerts?.length > 0) {
    const firstImageUrl = buildMediaUrl(priceAlerts[0].media)
    if (firstImageUrl !== '') {
      preload(firstImageUrl, {
        as: 'image',
        fetchPriority: 'high',
      })
    }
  }

  const onFilter = async (status: string | number) => {
    if (status === activeFilter) {
      return
    }
    const searchParams = new URLSearchParams(window.location.search)

    setActiveFilter(status)
    if (status) {
      searchParams.set('status', String(status))
    } else {
      searchParams.delete('status')
    }

    const pathSegments = pathname.split('/')
    const firstSegment = pathSegments[1]
    const secondSegment = pathSegments[2]

    let newPathname = pathname

    if (!Number.isNaN(parseInt(secondSegment))) {
      newPathname = `/${firstSegment}` // Remove the number
    }

    startTransition(() => {
      router.push(`${newPathname}?${searchParams.toString()}`)
    })
  }

  return (
    <section className='container mb-8 overflow-hidden'>
      {!!(breadcrumb && breadcrumb.length) && (
        <Breadcrumbs breadcrumbs={breadcrumb} />
      )}

      <Typography
        variant='h1'
        className='mb-4 mt-4 text-left text-2xl font-semibold text-primary'
      >
        {metaInformation?.h1 || d('title')} {d('items', { count: `${count}` })}
      </Typography>

      <ButtonGroup
        size='sm'
        variant='outlined'
        className='mb-4 flex-wrap gap-2 py-1'
      >
        {priceAlertsFilters?.length > 0 &&
          priceAlertsFilters.map((item, index) => (
            <Button
              key={index}
              onClick={() => onFilter(item.status)}
              className={`rounded-none border ${activeFilter === item?.status ? 'bg-primary text-white' : ''} `}
            >
              {item?.title}{' '}
              <span
                className={`ml-1 inline-block min-h-[22px] min-w-[22px] rounded-full p-1 ${activeFilter === item?.status ? 'bg-white text-primary' : 'bg-primary text-white'}`}
              >
                {item?.count || 0}
              </span>
            </Button>
          ))}
      </ButtonGroup>
      {isPending ? (
        <div className='mb-4 grid grid-cols-1 gap-4 lg:grid-cols-2'>
          {[...Array(limit)].map((_, index) => (
            <PriceHistoryCardSkeleton
              key={index}
              className={`border-border-light-silver-color max-w-full border`}
            />
          ))}
        </div>
      ) : (
        <div className='mb-4 grid grid-cols-1 gap-4 lg:grid-cols-2'>
          {(priceAlerts.length > 0 &&
            priceAlerts.map((item: PriceHistory.Card, index) => (
              <Fragment key={index}>
                <PriceHistoryCard
                  id={item.id}
                  productId={item.parentProductId}
                  productVariantId={item.nexgeonProductId}
                  mediaUrl={buildMediaUrl(
                    item?.history?.currentMinPriceProduct?.media
                  )}
                  mediaBucket={
                    item?.history?.currentMinPriceProduct?.media?.bucket
                  }
                  mediaKey={item?.history?.currentMinPriceProduct?.media?.key}
                  mediaAlt={item.name}
                  name={item.name}
                  price={item?.value}
                  currentPrice={item?.history?.currentMinPriceProduct?.price}
                  history={item.history}
                  url={item.pageUrl}
                  className={`border-border-light-silver-color max-w-full border`}
                  imageProps={{
                    priority: index === 0,
                    loading: index === 0 ? 'eager' : 'lazy',
                    sources: buildMediaSources(
                      item?.history?.currentMinPriceProduct?.media?.thumbnails
                    ),
                  }}
                  status={item.status}
                  priceReachedAt={item.priceReachedAt}
                />
              </Fragment>
            ))) || (
            <div className='col-span-1 px-10 text-2xl lg:col-span-2'>
              {d('no_price_alerts_found')}
            </div>
          )}
        </div>
      )}

      {displayPagination && (
        <Pagination
          activePage={page ?? 1}
          totalItemCount={count}
          itemsPerPage={limit}
        />
      )}
    </section>
  )
}
