import {
  Dialog,
  DialogBody,
  DialogHeader,
  Typography,
  IconButton,
  DialogFooter,
  Button,
} from '@material-tailwind/react'
import { useDictionary, usePage } from '@/providers'
import { useTransition } from 'react'

import { PriceHistory } from '@/types'
import Icon from '@/components/Icon'
import {
  fetchPriceAlertSubscription,
  fetchPriceAlertSubscriptionEdit,
} from '@/actions/parent-products'
import Cookies from 'js-cookie'
import { useRouter } from 'next/navigation'

import FormProvider, {
  useForm,
  yupResolver,
  Yup,
  RHFRadioGroup,
  RHFTextField,
} from '@/components/HookForm'
import Image from '@/components/Image'
import FakeLink from '@/components/FakeLink'
import React, { useState } from 'react'
import Alert from '@/components/Alert'
import { parsePrice, calculateDiscountedPrice } from '../utils/priceUtils'
import PriceAlertDeleteDialog from '@/components/PriceHistory/components/price-alert-delete-dialog'

type Props = {
  open: boolean
  handleOpen: () => void
  currentMinPriceProduct: PriceHistory.CurrentMinPriceProduct
  isCard?: boolean
  isActive?: boolean
}

export default function PriceAlertDialog({
  open,
  handleOpen,
  currentMinPriceProduct,
  isCard = false,
  isActive = true,
}: Props) {
  const router = useRouter()
  const [isPending, startTransition] = useTransition()

  const d = useDictionary('price_history_and_alert')
  const { loggedIn, pages } = usePage()

  const [errorMsg, setErrorMsg] = useState('')
  const [openCancelSubscriptionPopup, setOpenCancelSubscriptionPopup] =
    useState(false)
  const [success, setSuccess] = useState(false)
  const [priceAlarm, setPriceAlarm] = useState<PriceHistory.PriceAlarm | null>(
    currentMinPriceProduct?.priceAlert || null
  )

  const productPrice = parsePrice(currentMinPriceProduct.price)

  const AlertPriceSchema = Yup.object({
    priceOption: Yup.string().required(
      d('price_alert_popup_option_price_required')
    ),
    customPrice: Yup.string().when('priceOption', (priceOption, schema) => {
      return priceOption[0] === 'custom'
        ? schema.required(d('price_alert_popup_option_your_price_required'))
        : schema.notRequired()
    }),
  })

  const priceOptions = [
    { value: calculateDiscountedPrice(productPrice, 5), percentage: 5 },
    { value: calculateDiscountedPrice(productPrice, 10), percentage: 10 },
    { value: calculateDiscountedPrice(productPrice, 15), percentage: 15 },
    { value: 'custom', percentage: null },
  ]

  const matchedOption = priceOptions.find(
    (option) =>
      option.value ===
      (priceAlarm?.value
        ? parsePrice(priceAlarm?.value as string).toString()
        : '')
  )

  const defaultValues = {
    priceOption:
      priceAlarm && Object.keys(priceAlarm)?.length && isActive
        ? matchedOption
          ? matchedOption.value
          : 'custom'
        : calculateDiscountedPrice(productPrice, 5),
    customPrice: matchedOption
      ? ''
      : priceAlarm?.value
        ? parsePrice(priceAlarm?.value as string).toString()
        : '',
  }

  const methods = useForm({
    resolver: yupResolver(AlertPriceSchema),
    defaultValues,
  })

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = methods

  const onSubmit = handleSubmit(async (data) => {
    const newData = {
      redirectUrl: `/${pages.priceAlerts}`,
      price: Number(
        data.priceOption === 'custom' ? data.customPrice : data.priceOption
      ),
      productId: currentMinPriceProduct.id,
      variantId: currentMinPriceProduct.variantId,
    }

    if (!loggedIn) {
      Cookies.set(`alert_price_${newData.productId}`, JSON.stringify(newData), {
        expires: 1,
      })
      return (window.location.href = `/${pages.signIn}?alertPriceId=${newData.productId}`)
    }
    try {
      let result: any = {}

      if (priceAlarm && Object.keys(priceAlarm)?.length) {
        result = await fetchPriceAlertSubscriptionEdit(
          priceAlarm.id,
          newData.price
        )
      } else {
        result = await fetchPriceAlertSubscription(
          newData.price,
          newData.productId,
          newData.variantId
        )
      }

      if (!result?.success) {
        setErrorMsg(result?.data?.errors[0] || '')
        return
      }

      if (result?.success) {
        if (isCard) {
          startTransition(() => {
            setSuccess(true)
            setPriceAlarm(result?.data)
            router.refresh()
          })
        } else {
          setSuccess(true)
          setPriceAlarm(result?.data)
        }
      }
    } catch (error) {
      console.error('Error add subscription:', error)
    }
  })

  const priceOption = watch('priceOption')

  const closeModal = () => {
    handleOpen()
    setSuccess(false)
  }

  const cancelSubscription = () => {
    setPriceAlarm(null)

    const defaultValues = {
      priceOption: calculateDiscountedPrice(productPrice, 5),
      customPrice: '',
    }

    reset(defaultValues)
  }

  const getPriceLabel = (percentage: number | null, value: string) => {
    if (percentage === null) {
      return (
        <span
          className={`px-2 ${priceOption === value ? 'font-bold' : 'font-medium'}`}
        >
          {d('price_alert_popup_your_price_option')}
        </span>
      )
    }

    const discountedPrice = calculateDiscountedPrice(productPrice, percentage)

    return (
      <span
        className={`flex w-full items-center justify-between gap-2 rounded-md px-2 py-1 ${priceOption === value ? 'font-bold' : 'font-medium'}`}
      >
        <span className='bg-gray-100 px-1 py-0.5 text-xs'>
          {d('price_alert_popup_option_percentage', {
            percentage: `${percentage}`,
          })}
        </span>
        <span className='text-sm'>
          {d('price_alert_popup_option_price_prefix')}{' '}
          <span
            className='text-xl'
            dangerouslySetInnerHTML={{
              __html: `${discountedPrice} ${currentMinPriceProduct?.currency?.toString()}`,
            }}
          ></span>
        </span>
      </span>
    )
  }

  return (
    <>
      <Dialog
        open={open}
        handler={closeModal}
        className='rounded-none'
        size='md'
      >
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <DialogHeader className='justify-between pb-0'>
            <Typography variant='h3' className='text-lg font-bold text-primary'>
              {d('price_alert_popup_title')}
            </Typography>
            <IconButton
              aria-label={d('price_alert_popup_close_btn_title')}
              color='white'
              onClick={handleOpen}
              className='!shadow-none focus:!shadow-none'
            >
              <Icon size='10px' icon='cross' className='block' />
            </IconButton>
          </DialogHeader>
          {success && priceAlarm && !!Object.keys(priceAlarm)?.length ? (
            <>
              <DialogBody className='flex flex-col justify-center gap-4'>
                <div className='flex flex-col items-center justify-center gap-6'>
                  <Icon
                    icon='check-circle'
                    className='block text-black'
                    size='40px'
                  />
                  <Typography
                    variant='h3'
                    className='ext-lg font-bold text-primary'
                  >
                    {d('price_alert_success_msg_title')}
                  </Typography>
                </div>

                <div className='flex gap-2 border p-2'>
                  <div className='relative flex h-full min-h-[80px] w-[110px] items-center justify-center'>
                    {priceAlarm?.media && (
                      <Image
                        className='absolute inset-x-0 inset-y-0 size-full object-contain object-center'
                        src={priceAlarm?.media?.imageUrl}
                        width={260}
                        height={260}
                        alt={priceAlarm?.name || priceAlarm?.media?.bucket}
                      />
                    )}
                  </div>
                  <div className='flex flex-1 flex-col md:flex-row md:items-center'>
                    <Typography
                      variant='h3'
                      className='line-clamp-[2] text-md font-normal text-primary md:flex-1 md:text-center'
                    >
                      {priceAlarm.name}
                    </Typography>
                    <div className='flex items-center justify-between gap-1 md:w-[110px] md:flex-col-reverse'>
                      <span className='text-sm text-primary'>
                        {d('price_alert_success_price_title')}
                      </span>
                      <Typography
                        variant='lead'
                        className='text-xl font-medium text-primary md:text-right'
                      >
                        {priceAlarm.value}
                      </Typography>
                    </div>
                  </div>
                </div>

                <Typography
                  variant='lead'
                  className='mt-2 text-center text-md text-primary'
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: d('price_alert_success_msg_info', {
                        price: `${priceAlarm.value}`,
                        email: `${priceAlarm.email}`,
                      }),
                    }}
                  ></span>
                </Typography>
              </DialogBody>
              <DialogFooter
                className={`grid grid-cols-1 gap-2 ${!isCard ? 'sm:grid-cols-2' : ''}`}
              >
                {!isCard && (
                  <FakeLink
                    href={`/${pages.priceAlerts}`}
                    as={Button}
                    className='order-2 w-full !justify-center border-black text-black sm:order-none'
                    size='md'
                    variant='outlined'
                  >
                    {d('price_alert_subscription_list_btn_title')}
                  </FakeLink>
                )}

                <Button
                  className='w-full !justify-center bg-primary text-white !shadow-none'
                  size='md'
                  onClick={closeModal}
                >
                  {d('price_alert_success_btn_title')}
                </Button>
              </DialogFooter>
            </>
          ) : (
            <>
              <DialogBody>
                {errorMsg && (
                  <Alert state='error' className='mb-4'>
                    {errorMsg}
                  </Alert>
                )}

                {priceAlarm &&
                  !!Object.keys(priceAlarm)?.length &&
                  isActive && (
                    <Alert state='info' className='mb-4'>
                      <Typography variant='h4'>
                        {d('price_alert_subscription_already_exists_title')}
                      </Typography>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: d(
                            'price_alert_subscription_already_exists_info',
                            {
                              price: priceAlarm.value,
                            }
                          ),
                        }}
                      ></span>
                    </Alert>
                  )}
                <div className='grid grid-cols-1 gap-4 overflow-auto md:grid-cols-[2fr_1fr]'>
                  <div className='order-2 md:order-1'>
                    <RHFRadioGroup
                      name='priceOption'
                      label={d('price_alert_popup_option_title')}
                      options={priceOptions.map(({ value, percentage }) => ({
                        label: getPriceLabel(percentage, value),
                        value,
                      }))}
                    />

                    {priceOption === 'custom' && (
                      <RHFTextField
                        label={d('price_alert_popup_input_title')}
                        name='customPrice'
                        type='number'
                        inputMode='numeric'
                        variant='static'
                        className='!pr-2.5'
                      />
                    )}
                  </div>
                  <div className='order-1 flex gap-2 border p-2 md:order-2 md:flex-col'>
                    <div className='relative flex h-full min-h-[80px] w-[110px] items-center justify-center md:h-44 md:w-full'>
                      <Image
                        className='absolute inset-x-0 inset-y-0 size-full object-contain object-center'
                        src={currentMinPriceProduct?.media?.imageUrl}
                        width={260}
                        height={260}
                        alt={
                          currentMinPriceProduct?.name ||
                          currentMinPriceProduct?.media?.bucket
                        }
                      />
                    </div>
                    <div className='flex-1'>
                      <Typography
                        variant='h3'
                        className='line-clamp-[2] text-md font-normal text-primary'
                      >
                        {currentMinPriceProduct?.name}
                      </Typography>
                      <div className='flex items-center justify-between gap-2'>
                        <span className='text-sm text-primary'>
                          {d('price_alert_popup_price_info_title')}
                        </span>
                        <Typography
                          variant='lead'
                          className='text-xl font-medium text-primary md:text-right'
                        >
                          {currentMinPriceProduct?.price}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogBody>
              {priceAlarm && Object.keys(priceAlarm)?.length && isActive ? (
                <DialogFooter className='grid grid-cols-1 gap-2 sm:grid-cols-2'>
                  <Button
                    className='order-2 w-full !justify-center border-black text-black sm:order-none'
                    size='md'
                    variant='outlined'
                    onClick={() => {
                      handleOpen()
                      setOpenCancelSubscriptionPopup(true)
                    }}
                  >
                    {d('price_alert_subscription_cancel_btn_title')}
                  </Button>

                  <Button
                    className='w-full !justify-center bg-primary text-white !shadow-none'
                    size='md'
                    type='submit'
                    loading={isSubmitting || isPending}
                  >
                    {d('price_alert_edit_btn_title')}
                  </Button>
                </DialogFooter>
              ) : (
                <DialogFooter className='flex-col bg-gray-200'>
                  <div className='mb-4 w-full'>
                    <Typography
                      variant='h3'
                      className='text-lg font-bold text-primary md:text-2xl'
                    >
                      {d('price_alert_popup_info_title')}
                    </Typography>
                    <Typography
                      variant='paragraph'
                      className='text-base text-secondary'
                    >
                      {d('price_alert_popup_info_description')}
                    </Typography>
                  </div>
                  {!loggedIn && (
                    <Alert state='info' className='mb-4'>
                      {d('price_alert_sing_in_info_text')}
                      <FakeLink
                        as={'span'}
                        className='ml-2 underline hover:text-black'
                        href={`/${pages.signIn}`}
                      >
                        {d('price_alert_sing_in_btn_title')}
                      </FakeLink>
                    </Alert>
                  )}
                  <Button
                    className='w-full !justify-center bg-primary text-white'
                    size='md'
                    type='submit'
                    loading={isSubmitting || isPending}
                  >
                    {d('price_alert_submit_btn_title')}
                  </Button>
                </DialogFooter>
              )}
            </>
          )}
        </FormProvider>
      </Dialog>

      {!!priceAlarm?.id && (
        <PriceAlertDeleteDialog
          open={openCancelSubscriptionPopup}
          handleOpen={() => {
            setOpenCancelSubscriptionPopup(false)
          }}
          id={priceAlarm?.id}
          callback={cancelSubscription}
        />
      )}
    </>
  )
}
