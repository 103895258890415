import IconSvg from '@/components/IconSvg'
import React from 'react'

const AnchorLink = ({
  id,
  icon,
  text,
  className = '',
}: {
  id: string
  icon: string
  text: string
  className?: string
}) => (
  <a
    className={`flex flex-col items-center justify-center gap-2 px-2 py-1 text-md sm:text-base ${className}`}
    href={`#${id}`}
    onClick={(e) => {
      e.preventDefault()
      document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' })
    }}
  >
    <IconSvg className='size-6 fill-current' icon={icon} />
    {text}
  </a>
)

export default AnchorLink
