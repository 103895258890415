import {
  Dialog,
  DialogBody,
  DialogHeader,
  Typography,
  IconButton,
  DialogFooter,
  Button,
} from '@material-tailwind/react'
import { useDictionary } from '@/providers'
import Icon from '@/components/Icon'
import { fetchPriceAlertCancelSubscription } from '@/actions/parent-products'

import React, { useState, useTransition } from 'react'
import Alert from '@/components/Alert'
import { useRouter } from 'next/navigation'

type Props = {
  open: boolean
  handleOpen: () => void
  id: number
  callback?: () => void
  isCard?: boolean
}

export default function PriceAlertDeleteDialog({
  id,
  open,
  handleOpen,
  isCard = false,
  callback = () => {},
}: Props) {
  const d = useDictionary('price_history_and_alert_cancel_modal')
  const router = useRouter()
  const [isPending, startTransition] = useTransition()

  const [errorMsg, setErrorMsg] = useState('')
  const [success, setSuccess] = useState(false)
  const [cancelSubscriptionLoading, setCancelSubscriptionLoading] =
    useState(false)

  const cancelSubscription = async () => {
    if (!id) return

    try {
      setCancelSubscriptionLoading(true)

      const result = await fetchPriceAlertCancelSubscription(id)

      if (result?.success) {
        if (isCard) {
          startTransition(() => {
            setErrorMsg('')
            setCancelSubscriptionLoading(false)
            setSuccess(true)
            router.refresh()
          })
        } else {
          setErrorMsg('')
          setCancelSubscriptionLoading(false)
          setSuccess(true)
        }
      } else {
        setErrorMsg(result?.data.errors[0] || '')
      }
    } catch (error) {
      console.error('Error cancel subscription:', error)
      setCancelSubscriptionLoading(false)
    }
  }

  const closeModal = () => {
    handleOpen()
    callback()
    setSuccess(false)
  }

  return (
    <Dialog
      open={open}
      handler={closeModal}
      className='max-h-screen overflow-y-auto rounded-none'
      size='md'
    >
      <DialogHeader className='justify-between pb-0'>
        <Typography variant='h3' className='text-lg font-bold text-primary'>
          {d('price_alert_delete_popup_title')}
        </Typography>
        <IconButton
          aria-label={d('price_alert_popup_close_btn_title')}
          color='white'
          onClick={handleOpen}
          className='!shadow-none focus:!shadow-none'
        >
          <Icon size='10px' icon='cross' className='block' />
        </IconButton>
      </DialogHeader>
      {success ? (
        <>
          <DialogBody>
            <Typography variant='paragraph' className='text-xl text-primary'>
              {d('price_alert_cancel_delete_popup_success_info')}
            </Typography>
          </DialogBody>
          <DialogFooter className='grid grid-cols-1'>
            <Button
              className='w-full !justify-center border-black bg-primary text-white !shadow-none'
              size='md'
              onClick={closeModal}
            >
              {d('price_alert_cancel_delete_popup_success_btn_title')}
            </Button>
          </DialogFooter>
        </>
      ) : (
        <>
          <DialogBody>
            {errorMsg && (
              <Alert state='error' className='mb-4'>
                {errorMsg}
              </Alert>
            )}

            <Typography variant='paragraph' className='text-xl text-primary'>
              {d('price_alert_cancel_delete_popup_info')}
            </Typography>
          </DialogBody>
          <DialogFooter className='grid grid-cols-1 gap-2 sm:grid-cols-2'>
            <Button
              className='w-full !justify-center border-black text-black !shadow-none'
              size='md'
              variant='outlined'
              onClick={closeModal}
            >
              {d('price_alert_cancel_delete_popup_btn_title')}
            </Button>
            <Button
              className='w-full !justify-center border-black bg-primary text-white !shadow-none'
              size='md'
              loading={cancelSubscriptionLoading || isPending}
              onClick={cancelSubscription}
            >
              {d('price_alert_delete_popup_btn_title')}
            </Button>
          </DialogFooter>
        </>
      )}
    </Dialog>
  )
}
