import { useMemo } from 'react'

export function useFilteredAndSortedVariants(
  variants: any[],
  activeOptions: { [key: string]: string }
) {
  return useMemo(() => {
    return filterAndSortVariants(variants, activeOptions)
  }, [variants, activeOptions]) // Dependencies for useMemo
}

export function filterAndSortVariants(
  variants: any[],
  activeOptions: { [key: string]: string }
) {
  const updatedVariants = variants?.map((variant) => {
    const isDisabled = Object.keys(activeOptions).some((key) => {
      const optionValue = activeOptions[key]
      if (Array.isArray(variant[key])) {
        return !variant[key].some((item: any) => item.slug === optionValue)
      } else if (variant[key]) {
        return variant[key].slug !== optionValue
      }
      return false
    })

    return { ...variant, disabled: isDisabled }
  })

  const filteredVariants = updatedVariants?.sort(
    (a, b) => Number(a.disabled) - Number(b.disabled)
  )

  const notDisabledVariantsCount = filteredVariants?.filter(
    (variant) => !variant.disabled
  ).length

  return { filteredVariants, notDisabledVariantsCount }
}
