'use client'

import React, { useEffect } from 'react'
import { ProductDetailComponent, Product, Outfit, Breadcrumb } from '@/types'
import { useDictionary, usePage } from '@/providers'
import Breadcrumbs from '@/components/Breadcrumbs'
import { ImageGallery, ProductInfo } from '@/components/ProductDetail'
import RelatedProducts from '@/components/RelatedProducts'
import RelatedOutfits from '@/components/RelatedOutfits'
import { setCookieForLastSeen } from '@/utils/cookies'
import {
  GetComponentTrackingSpecificData,
  NG_WAS_PRODUCT_DETAIL,
  NG_WAS_PRODUCT_DETAIL_SIMILAR_PRODUCTS_LISTING,
} from '@/components/NgTracker'

type Props = {
  product: ProductDetailComponent.ProductDetail
  similarProducts: Product.SimilarProducts
  outfits: Outfit.SimilarOutfits
  breadcrumb: Array<Breadcrumb>
  lastSeenLimit: number
  favorite?: boolean
}

export default function ProductDetail({
  product,
  similarProducts,
  outfits,
  breadcrumb,
  lastSeenLimit,
}: Props) {
  const d = useDictionary('product_detail')
  const { channelShopUrlTags, channelId } = usePage()

  useEffect(() => {
    setCookieForLastSeen('product', product.slug as string, lastSeenLimit)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className='overflow-hidden'>
      <div className='container'>
        <Breadcrumbs breadcrumbs={breadcrumb} />

        <div className='flex flex-col gap-8 lg:flex-row'>
          <div className='lg:basis-1/2'>
            <ImageGallery medias={product.medias} alt={product.name} />
          </div>
          <div className='flex flex-col lg:basis-2/4'>
            <ProductInfo
              id={product.id}
              link={product.link}
              brandName={product.brand.value}
              name={product.name}
              price={{
                price: product.price,
                previousPrice: product.previousPrice,
                sale: product.sale,
              }}
              favorite={product?.favorite}
              properties={product.properties}
              description={product.generatedDescription}
              shareImageUrl={product.medias?.[0]?.imageUrl}
              shopId={product.shopId}
              componentTrackingSpecificData={GetComponentTrackingSpecificData(
                '',
                NG_WAS_PRODUCT_DETAIL,
                channelShopUrlTags,
                channelId
              )}
            />
          </div>
        </div>
      </div>

      {!!(similarProducts.products && similarProducts.products.length) && (
        <RelatedProducts
          productId={product.id}
          maxCount={similarProducts.count}
          products={similarProducts.products}
          title={d('related_product_title')}
          className='pb-10 pt-8'
          componentTrackingSpecificData={GetComponentTrackingSpecificData(
            '',
            NG_WAS_PRODUCT_DETAIL_SIMILAR_PRODUCTS_LISTING,
            channelShopUrlTags,
            channelId
          )}
        />
      )}

      {!!(outfits.outfits && outfits.outfits.length) && (
        <RelatedOutfits
          outfitId={product.id}
          count={outfits.count}
          outfits={outfits.outfits}
          title={d('product_outfit_title', { name: product.name })}
          type={Outfit.RelatedOutfits.PRODUCT}
          className='pb-10 pt-8'
        />
      )}
    </section>
  )
}
