export interface PriceDate {
  price: string
  date: string
}

export function generatePriceAndDateArrays(data: PriceDate[] = []): {
  prices: number[]
  dates: string[]
  lastPrice: number
} {
  if (!Array.isArray(data) || data.length === 0) {
    return { prices: [], dates: [], lastPrice: 0 }
  }

  const prices: number[] = data
    .map((item) => parseFloat(item.price))
    .filter((price) => !isNaN(price))

  const dates: string[] = data.map((item) => item.date)

  const lastPrice = prices.length > 0 ? prices[prices.length - 1] : 0

  return { prices, dates, lastPrice }
}
