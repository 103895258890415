'use client'

import { useState } from 'react'
import { List, ListItem } from '@material-tailwind/react'
import { StaticPageComponent } from '@/types'
import Slider, { breakpoints, SliderItem } from '@/components/Slider'
import Link from '@/components/Link'
import { usePathname } from 'next/navigation'
import { useEventListener } from '@/hooks/use-event-listener'

type Props = {
  elements: Array<StaticPageComponent.SidebarItem>
  className?: string
}

export default function Sidebar({ elements, className = '' }: Props) {
  const [isMobile, setIsMobile] = useState(false)
  useEventListener('resize', () =>
    setIsMobile(window.innerWidth < breakpoints.md)
  )
  const pathname = usePathname()
  const sliderProps = {
    slidesPerView: 'auto' as const,
    autoHeight: true,
    spaceBetween: 10,
  }

  const LinkList = ({
    element,
    ...options
  }: {
    element: StaticPageComponent.SidebarItem
  }) => (
    <Link href={element.url} className='font-medium text-black'>
      <ListItem
        {...options}
        className={`rounded-none ${pathname === element.url ? 'border' : ''}`}
      >
        {element.name}
      </ListItem>
    </Link>
  )

  return (
    <List
      className={`${className} mb-6 flex-row px-0 md:mb-0 md:flex-col md:px-2`}
    >
      {isMobile ? (
        <Slider className='!ml-0 overflow-visible text-left' {...sliderProps}>
          {elements?.map((element, key) => (
            <SliderItem key={key} className='!w-auto'>
              <LinkList element={element} />
            </SliderItem>
          ))}
        </Slider>
      ) : (
        elements?.map((element, key) => (
          <LinkList element={element} key={key} />
        ))
      )}
    </List>
  )
}
