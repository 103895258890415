import { Fragment } from 'react'
import { preload } from 'react-dom'
import { Typography } from '@material-tailwind/react'
import {
  Outfit,
  Breadcrumb,
  Filters as Facets,
  Suggestions as SuggestionsType,
  Thumbnails,
  PictureSources,
  Media,
  OutfitListingComponent as Listing,
} from '@/types'
import { usePage, useDictionary } from '@/providers'
import Breadcrumbs from '@/components/Breadcrumbs'
import OutfitCard from '@/components/OutfitCard'
import Filters, {
  generateFilterInitialState,
  useFilters,
} from '@/components/Filters'
import Suggestions from '@/components/Suggestions'
import SuggestedCategories from '@/components/SuggestedCategories'
import Pagination from '@/components/Pagination'
import StaticDescription from '@/components/StaticDescription'

type Props = {
  componentId: number
  outfits: Array<Outfit.Outfit>
  count: number
  limit: number
  displayFilter: boolean
  displayPagination: boolean
  breadcrumb: Array<Breadcrumb>
  facets: Facets.Facets
  mainContentSuggestions: Array<SuggestionsType.Suggestions>
  mainContentOnTop: Array<Listing.Category>
  suggestions: SuggestionsType.Suggestions
  page?: number
  description?: string
}

export default function OutfitListing({
  componentId,
  outfits,
  count,
  limit,
  page,
  displayPagination,
  displayFilter,
  breadcrumb,
  facets,
  suggestions,
  mainContentSuggestions,
  mainContentOnTop,
  description,
}: Props) {
  const d = useDictionary('outfit_listing')
  const { pages, metaInformation } = usePage()

  const initialFilterState = generateFilterInitialState([
    ...(facets?.top || []),
    ...(facets?.left || []),
  ])

  const {
    filters,
    onFilter,
    resetFilter,
    resetAllFilters,
    applyFilters,
    filterCount,
    showMoreFilters,
  } = useFilters(initialFilterState, componentId, pages)

  const getSuggestionIndex = (i: number): number => Math.ceil((i + 1) / 6) - 1

  const buildMediaUrl = (media?: Media): string => {
    if (media === undefined) {
      return ''
    }

    if (
      media.thumbnails === undefined ||
      !('750x750' in media.thumbnails) ||
      !('490x490' in media.thumbnails) ||
      !('330x330' in media.thumbnails)
    ) {
      return media.imageUrl
    }

    return media.thumbnails['330x330']
  }

  const buildMediaSources = (
    thumbnails?: Thumbnails
  ): PictureSources | undefined => {
    if (
      thumbnails === undefined ||
      !('750x750' in thumbnails) ||
      !('490x490' in thumbnails) ||
      !('330x330' in thumbnails)
    ) {
      return
    }

    return {
      '420': {
        src: thumbnails['330x330'],
        size: 330,
      },
      '520': {
        src: thumbnails['490x490'],
        size: 490,
      },
      '767': {
        src: thumbnails['750x750'],
        size: 750,
      },
      '1023': {
        src: thumbnails['490x490'],
        size: 490,
      },
    }
  }

  if (outfits?.length > 0) {
    const firstImageUrl = buildMediaUrl(outfits[0].media)
    if (firstImageUrl !== '') {
      preload(firstImageUrl, {
        as: 'image',
        fetchPriority: 'high',
      })
    }
  }

  return (
    <section className='container mb-8 overflow-hidden'>
      <Breadcrumbs breadcrumbs={breadcrumb} />
      <Typography
        variant='h1'
        className='mb-8 mt-4 text-left text-2xl font-semibold text-primary'
      >
        {metaInformation?.h1 || d('title')}
      </Typography>
      {mainContentOnTop.length > 0 && (
        <SuggestedCategories array={mainContentOnTop} />
      )}
      <div className='mb-2 flex items-center justify-between'>
        <Typography
          id='listing-result-count'
          data-value={count}
          variant='paragraph'
          className='py-1.5 text-left text-sm text-secondary'
        >
          {d('items', { count: `${count}` })}
        </Typography>

        {displayFilter && (
          <Filters
            variant='mobile'
            components={[...(facets?.top || []), ...(facets?.left || [])]}
            filters={filters}
            filterCount={filterCount.total}
            onFilter={onFilter}
            onApplyFilters={applyFilters}
            onResetFilter={resetFilter}
            onResetAllFilter={resetAllFilters}
            onShowMoreFilters={showMoreFilters}
            className='lg:hidden'
          />
        )}
      </div>

      {suggestions?.links && suggestions?.links?.length > 0 && (
        <Suggestions list={suggestions.links} title={suggestions.groupTitle} />
      )}
      <div className='lg:grid lg:grid-cols-4 lg:gap-6'>
        {displayFilter && (
          <Filters
            variant='vertical'
            components={facets?.left}
            filters={filters}
            filterCount={filterCount.total}
            onFilter={onFilter}
            onResetFilter={resetFilter}
            onResetAllFilter={resetAllFilters}
            onShowMoreFilters={showMoreFilters}
            className='hidden lg:flex'
          />
        )}
        <div className={`${displayFilter ? 'lg:col-span-3' : 'lg:col-span-4'}`}>
          {displayFilter && (
            <Filters
              variant='horizontal'
              components={facets?.top}
              filters={filters}
              filterCount={filterCount.total}
              onFilter={onFilter}
              onResetFilter={resetFilter}
              onResetAllFilter={resetAllFilters}
              onShowMoreFilters={showMoreFilters}
              className='hidden lg:flex'
            />
          )}
          <div className='mt-4 grid grid-cols-1 gap-y-8 md:grid-cols-2 md:gap-4 lg:grid-cols-3'>
            {(outfits.length > 0 &&
              outfits.map((item: Outfit.Outfit, index: number) => (
                <Fragment key={index}>
                  <OutfitCard
                    id={item.id}
                    mediaUrl={buildMediaUrl(item.media)}
                    mediaBucket={item.media?.bucket}
                    mediaKey={item.media?.key}
                    mediaAlt={item.generatedDescription ?? item.name}
                    title={item.generatedDescription ?? item.name}
                    products={item.products}
                    badges={item?.badges}
                    slug={item.slug}
                    subType={item.subType}
                    favorite={item?.favorite}
                    className='max-w-full'
                    imageProps={{
                      priority: index === 0,
                      loading: index === 0 ? 'eager' : 'lazy',
                      sources: buildMediaSources(item.media?.thumbnails),
                    }}
                    urlRef={true}
                  />
                  {((index + 1) % 6 === 0 || index === outfits.length - 1) &&
                    mainContentSuggestions[getSuggestionIndex(index)] !==
                      undefined && (
                      <Suggestions
                        mainContent={true}
                        list={
                          mainContentSuggestions[getSuggestionIndex(index)]
                            ?.links
                        }
                        title={
                          mainContentSuggestions[getSuggestionIndex(index)]
                            ?.groupTitle
                        }
                        className='col-span-1 md:col-span-2 lg:col-span-3'
                      />
                    )}
                </Fragment>
              ))) ||
              d('no_outfits_found')}
          </div>
          {displayPagination && (
            <Pagination
              activePage={page ?? 1}
              totalItemCount={count}
              itemsPerPage={limit}
            />
          )}
        </div>
      </div>
      {description && <StaticDescription content={description} />}
    </section>
  )
}
