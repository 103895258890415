import { useEffect, useState } from 'react'
import { Properties } from '@/types'
import { Button, Typography } from '@material-tailwind/react'
import { useDictionary } from '@/providers'
import FakeLink from '@/components/FakeLink'
import Icon from '@/components/Icon'
import Accordion from '@/components/Accordion'
import ShareButtons from '@/components/ShareButtons'
import WishlistButton from '@/components/WishlistButton'
import ProductProperties from '@/components/ProductProperties'
import emitter, { DeleteFavoriteProductEvent } from '@/utils/events'
import {
  CurrentPageTrackingData,
  GetExternalLinkTrackingProductParams,
  GetExternalLinkTrackingUrlParams,
  TrackingExternalUrlSpecificData,
  BuildHref,
} from '@/components/NgTracker'

type Price = {
  price: string
  previousPrice: string
  sale: number
}

type Props = {
  id: number
  link: string
  brandName: string
  name: string
  price: Price
  properties: Array<Properties.Properties>
  description: string
  shareImageUrl: string
  shopId: number
  componentTrackingSpecificData?: TrackingExternalUrlSpecificData | undefined
  favorite?: boolean
}

export function ProductInfo({
  id,
  link,
  brandName,
  name,
  price,
  properties,
  description,
  shareImageUrl,
  shopId,
  componentTrackingSpecificData = {},
  favorite = false,
}: Props) {
  const d = useDictionary('product_detail')

  const [trackingParams, setTrackingParams] = useState('')

  useEffect(() => {
    setTrackingParams(
      GetExternalLinkTrackingUrlParams(componentTrackingSpecificData, shopId)
    )
  }, [CurrentPageTrackingData])

  return (
    <div className='flex flex-col'>
      <Typography as='p' variant='h3' className='mb-0.5 text-left text-primary'>
        {brandName}
      </Typography>

      <Typography
        as='h1'
        variant='h1'
        className='mb-7 text-left text-lg font-normal text-primary'
      >
        {name}
      </Typography>

      <div className='flex flex-col gap-2'>
        {!!(price.sale && price.previousPrice) && (
          <div className='flex items-center gap-1.5'>
            <Typography
              variant='lead'
              className='flex items-center gap-1.5 bg-tertiary-red px-1.5 py-0.5 text-left text-xs text-white'
            >
              <Icon icon='badge-percent' size='14px' />
              {' -'}
              {price.sale}
              {'%'}
            </Typography>

            <Typography
              variant='lead'
              className='text-left text-base text-secondary'
            >
              {price.previousPrice}
            </Typography>
          </div>
        )}

        <Typography
          as='p'
          variant='h4'
          className='text-left text-lg text-primary'
        >
          {price.price}
        </Typography>
      </div>

      <ProductProperties properties={properties} className='my-10' />

      <div className='flex gap-2'>
        <FakeLink
          as={Button}
          href={BuildHref(
            link,
            trackingParams,
            GetExternalLinkTrackingProductParams(id, shopId)
          )}
          target='_blank'
          variant='filled'
          className='h-full w-full bg-buy-button-bg py-1 text-lg font-normal capitalize text-white shadow-none hover:shadow-none focus:shadow-none'
        >
          {d('to_shop_btn')}
        </FakeLink>
        <WishlistButton
          id={id}
          type='product'
          isChecked={favorite}
          className='!relative right-0 top-0 h-11 w-11 rounded-none border border-light-gray-color shadow-none hover:shadow-none'
          iconSize='24px'
          onDelete={() => emitter.emit(DeleteFavoriteProductEvent, id)}
        />
      </div>

      <div className='mt-10'>
        {description && (
          <Accordion title={d('tabs.description')} className='text-left'>
            {description}
          </Accordion>
        )}

        <div className='flex items-center justify-between gap-3 bg-body-bg-secondary px-4 py-2'>
          <Typography
            variant='lead'
            className='text-left text-sm font-normal text-primary'
          >
            {d('tabs.share')}
          </Typography>

          <div className='flex gap-2.5'>
            <ShareButtons description={description} mediaUrl={shareImageUrl} />
          </div>
        </div>
      </div>
    </div>
  )
}
