import { useMemo } from 'react'

export function useFilteredOffers(
  offers: any[],
  activeOptions: { [key: string]: string }
) {
  return useMemo(() => {
    return offers.filter((offer) => {
      for (const key in activeOptions) {
        const optionValue = activeOptions[key]
        if (Array.isArray(offer[key])) {
          if (!offer[key].some((item: any) => item.slug === optionValue)) {
            return false
          }
        } else if (offer[key]) {
          if (offer[key].slug !== optionValue) {
            return false
          }
        }
      }
      return true
    })
  }, [offers, activeOptions])
}
