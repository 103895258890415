export enum Device {
  S = 's',
  M = 'm',
  L = 'l',
}

export enum TechnicalName {
  HEADER = 'header',
  FOOTER = 'footer',
  NEWSLETTER = 'newsletter',
  BANNER_TEXT_LINKS = 'banner--text-links',
  URL_SLIDER_TEXT_OVERLAP = 'url-slider--text-overlap',
  URL_SLIDER_TEXT_BOTTOM = 'url-slider--text-bottom',
  BANNER_SLIDER_TWO_COLUMNS = 'banner-slider--two-columns',
  URL_SLIDER_SEPARATE_BUTTON = 'url-slider--separate-button',
  URL_SLIDER_WITHOUT_TEXT_SMALLER_ITEMS = 'url-slider--without-text-smaller-items',
  CREATIVE_SLIDER_OUTFIT_SLIDER = 'creative-slider--outfit-slider',
  CREATIVE_DETAIL_OUTFIT = 'creative-detail--outfit',
  CREATIVE_DETAIL_PRODUCT = 'creative-detail--product',
  BRAND_LISTING = 'brand',
  LAST_SEEN = 'last-seen',
  SIGN_UP = 'sign-up',
  SIGN_IN = 'sign-in',
  PROFILE = 'account',
  USER_VERIFICATION = 'user-verification',
  FORGOT_PASSWORD = 'forgot-password',
  RESET_PASSWORD = 'reset-password',
  ABOUT_US = 'info--about-us',
  DATA_POLICY = 'info--data-policy',
  IMPRESSUM = 'info--imprint',
  TERM_OF_USE = 'info--terms-of-use',
  CONTACT_US = 'info--contact-us',
  INTERNAL_LINK = 'link--internal-link',
  FAQ = 'info--faq',
  ERROR_404 = 'error--404',
  ERROR_500 = 'error--500',
  CREATIVE_LISTING_PRODUCT = 'creative-listing--product',
  CREATIVE_LISTING_COMBINATION = 'creative-listing--combination',
  COMBINATION_FILTER = 'combination--filter',
  CREATIVE_LISTING_OUTFIT = 'creative-listing--outfit',
  FAVORITE = 'favorite',
  FAVORITE_OUTFITS = 'favorite-outfits',
  FAVORITE_PRODUCTS = 'favorite-products',
  SITEMAP = 'sitemap',
  STATIC_TEXT = 'static-text',
  BLOG_LISTING = 'blog',
  CREATIVE_DETAIL_PARENT_PRODUCT = 'creative-detail--parent-product',
  INFO_BECOME_PARTNER = 'info--become-partner',
  INFO_ADVERTISING_SERVICES = 'info--advertising-services',
  PRICE_ALERTS = 'price-alerts',
}

export type Config = object

export type Group = {
  id: string
  name: string
}

export type BuilderComponent = {
  id: string
  name: string
  serviceId: string
  technicalName: TechnicalName
  group: Group
}

export interface Component {
  id: number
  position: number
  config: Config
  builderComponent: BuilderComponent
  device: Array<Device>
}
