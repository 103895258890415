'use client'

import { Controller, useFormContext } from 'react-hook-form'

import { Radio, type RadioProps, Typography } from '@material-tailwind/react'

type Props = RadioProps & {
  name: string
  options: Array<{ label: string | JSX.Element; value: string }>
  helperText?: string
  disabled?: boolean
}

export default function RHFRadioGroup({
  name,
  options,
  label,
  helperText,
  disabled = false,
}: Props) {
  const { control } = useFormContext()

  return (
    <div>
      {label && (
        <Typography variant='h6' color='gray' className='mb-2'>
          {label}
        </Typography>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <div className='flex flex-col'>
            {options.map((option) => (
              <div key={option.value} className='mb-2 flex items-center'>
                <Radio
                  {...field}
                  value={option.value}
                  checked={field.value === option.value}
                  onChange={() => field.onChange(option.value)}
                  disabled={disabled}
                  label={option.label}
                />
              </div>
            ))}
            {(!!error || helperText) && (
              <Typography
                variant='small'
                color='gray'
                className={`mt-2 text-left font-normal ${error ? 'text-red-500' : ''}`}
              >
                {error ? error?.message : helperText}
              </Typography>
            )}
          </div>
        )}
      />
    </div>
  )
}
