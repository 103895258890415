'use client'

import { useState, useEffect, useRef } from 'react'
import { Typography, Button } from '@material-tailwind/react'
import { Outfit, Thumbnails, PictureSources, Media } from '@/types'
import { useDictionary } from '@/providers'
import OutfitCard from '@/components/OutfitCard'
import { fetchSimilarOutfits } from '@/utils/actions/outfit'
import { fetchProductOutfits } from '@/utils/actions/product'
import IconSvg from '@/components/IconSvg'

type Props = {
  title: string
  count: number
  outfitId: number
  outfits: Array<Outfit.Outfit>
  type: Outfit.RelatedOutfits
  categoryId?: number
  limit?: number
  className?: string
  infiniteScroll?: boolean
}

export default function RelatedOutfits({
  title,
  count,
  outfitId,
  outfits,
  type,
  limit = 8,
  categoryId,
  className = '',
  infiniteScroll = false,
}: Props) {
  const d = useDictionary('related_outfits')

  const [outfitsList, setOutfitsList] = useState(outfits)
  const [loading, setLoading] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)

  const observerRef = useRef<IntersectionObserver | null>(null)
  const loadMoreRef = useRef<HTMLDivElement>(null)

  const getFetch = () => {
    switch (type) {
      case Outfit.RelatedOutfits.SIMILAR:
        return fetchSimilarOutfits(
          outfitId,
          outfitsList.length,
          limit,
          categoryId!
        )
      case Outfit.RelatedOutfits.PRODUCT:
        return fetchProductOutfits(outfitId, outfitsList.length, limit)
    }

    throw new Error('Type is not supported!')
  }

  const onShowMore = async () => {
    setLoading(true)
    try {
      const { data } = await getFetch()
      setOutfitsList(outfitsList.concat(data.outfits))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      /* empty */
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!infiniteScroll) return

    const handleScroll = () => {
      const currentScrollPos = window.scrollY
      const directionDown = currentScrollPos > scrollPosition
      setScrollPosition(currentScrollPos)
      return directionDown
    }

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      const isIntersecting = entries[0].isIntersecting
      const isScrollingDown = handleScroll()

      if (
        isIntersecting &&
        isScrollingDown &&
        outfitsList.length < count &&
        !loading
      ) {
        onShowMore()
      }
    }

    observerRef.current = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    })

    if (loadMoreRef.current) {
      observerRef.current.observe(loadMoreRef.current)
    }

    return () => {
      if (observerRef.current && loadMoreRef.current) {
        observerRef.current.unobserve(loadMoreRef.current)
      }
    }
  }, [infiniteScroll, outfitsList, count, loading, scrollPosition])

  const buildMediaUrl = (media?: Media): string => {
    if (media === undefined) {
      return ''
    }

    if (
      media.thumbnails === undefined ||
      !('490x490' in media.thumbnails) ||
      !('330x330' in media.thumbnails)
    ) {
      return media.imageUrl
    }

    return media.thumbnails['330x330']
  }

  const buildMediaSources = (
    thumbnails?: Thumbnails
  ): PictureSources | undefined => {
    if (
      thumbnails === undefined ||
      !('490x490' in thumbnails) ||
      !('330x330' in thumbnails)
    ) {
      return
    }

    return {
      '360': {
        src: thumbnails['330x330'],
        size: 330,
      },
      '479': {
        src: thumbnails['490x490'],
        size: 490,
      },
      '700': {
        src: thumbnails['330x330'],
        size: 330,
      },
      '1279': {
        src: thumbnails['490x490'],
        size: 490,
      },
    }
  }

  if (outfits && !outfits?.length) {
    return
  }

  return (
    <div className={`container ${className}`}>
      <div className='flex flex-col gap-4 py-8 md:flex-row md:items-center'>
        <Typography
          as='p'
          variant='h2'
          className='flex-1 text-left text-3xl font-semibold text-primary underline underline-offset-2'
        >
          {title}
          {d('count', { count: `${count}` })}
        </Typography>
      </div>

      <div className='w-full'>
        <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
          {outfitsList?.map((item, index) => (
            <OutfitCard
              key={index}
              id={item.id}
              mediaUrl={buildMediaUrl(item.media)}
              mediaAlt={item.generatedDescription ?? item.name}
              mediaBucket={item.media?.bucket}
              mediaKey={item.media?.key}
              title={item.name}
              products={item.products}
              badges={item?.badges}
              slug={item.slug}
              subType={item.subType}
              className='max-w-full'
              favorite={item?.favorite}
              imageProps={{
                sources: buildMediaSources(item.media?.thumbnails),
              }}
            />
          ))}
        </div>

        {!infiniteScroll && outfitsList.length < count && (
          <Button
            loading={loading}
            variant='outlined'
            className='mx-auto mt-6 flex items-center justify-between border border-secondary leading-[150%] text-primary'
            onClick={onShowMore}
          >
            {d('show_more_btn')}
          </Button>
        )}

        {infiniteScroll && loading && (
          <div className='mt-6 flex justify-center'>
            <IconSvg className='size-8 fill-black' icon='loading' />
          </div>
        )}

        {infiniteScroll && !loading && outfitsList.length < count && (
          <div ref={loadMoreRef} className='h-10' />
        )}
      </div>
    </div>
  )
}
