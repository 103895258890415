'use client'

import React, { useState, useEffect } from 'react'
import { Button, Typography } from '@material-tailwind/react'
import { useDictionary } from '@/providers'
import ProductCard from '@/components/ProductCard'
import { Product } from '@/types'
import { getFavorite } from '@/actions/favorite'
import emitter, { DeleteFavoriteProductEvent } from '@/utils/events'

type Props = {
  products: Array<Product.Product>
  count: number
  typeCode: number
}

export default function Favorite({ products, count, typeCode }: Props) {
  const d = useDictionary('favorite')

  const [productsList, setProductsList] =
    useState<Array<Product.Product>>(products)
  const [productCount, setProductCount] = useState<number>(count)
  const [loading, setLoading] = useState<boolean>(false)

  const handleShowMore = async () => {
    setLoading(true)

    const { data, success } = await getFavorite({
      type: typeCode,
      offset: productsList.length,
    })

    if (success) {
      setProductsList(productsList.concat(data.entries))
      setProductCount(data.count)
    }
    setLoading(false)
  }

  useEffect(() => {
    const handleRemove = (id: number) => {
      setProductsList((prevList: Array<Product.Product>) =>
        prevList.filter((i) => i.id !== id)
      )
      setProductCount((prevCount) => prevCount - 1)
    }

    emitter.on(DeleteFavoriteProductEvent, handleRemove)

    return () => {
      emitter.off(DeleteFavoriteProductEvent, handleRemove)
    }
  }, [])

  return (
    <section className='container py-4 2xl:py-8'>
      <Typography
        variant='paragraph'
        className='pb-4 text-left text-2xl font-semibold md:pb-6'
      >
        {d('product_card.name')}
      </Typography>
      <Typography
        variant='paragraph'
        className='py-4 text-left text-sm font-normal text-secondary md:py-6'
      >
        {productCount} {d('saved_items')}
      </Typography>
      <div className='w-full'>
        <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
          {productsList.map((product: Product.Product) => (
            <ProductCard
              key={product.id}
              id={product.id}
              href={product.link}
              slug={product.slug}
              parentUrl={product.parentProduct?.url}
              parentOfferCount={product.parentProduct?.offerCount}
              generatedDescription={product.generatedDescription}
              shop={product.shop}
              mediaUrl={product.media?.imageUrl}
              mediaAlt={product.generatedDescription ?? product.name}
              mediaBucket={product.media?.bucket}
              mediaKey={product.media?.key}
              title={product.name}
              brandName={product?.brand?.value}
              price={product.price}
              hasSale={product.hasSale}
              previousPrice={product.previousPrice}
              available={product.available}
              badges={product?.badges}
              shopId={product.shopId}
              showSimilar
              favorite={product.favorite}
              className='max-w-full border border-transparent hover:border-light-silver-color'
            />
          ))}
        </div>
        {productsList.length < productCount && (
          <Button
            loading={loading}
            variant='outlined'
            className='mx-auto mt-6 border border-secondary leading-[150%] text-primary'
            onClick={handleShowMore}
          >
            {d('show_more_btn')}
          </Button>
        )}
      </div>
    </section>
  )
}
