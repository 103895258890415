import { useMemo } from 'react'
import deepMerge from '@/utils/deep-merge'
import type { ApexOptions } from 'apexcharts'

// Define a default Tailwind-based color palette
const colors = [
  '#3b82f6', // blue-500 (primary)
  '#facc15', // yellow-400 (warning)
  '#06b6d4', // cyan-500 (info)
  '#ef4444', // red-500 (error)
  '#22c55e', // green-500 (success)
  '#f59e0b', // yellow-500 (warning dark)
  '#15803d', // green-700 (success darker)
  '#0e7490', // cyan-700 (info dark)
  '#155e75', // cyan-900 (info darker)
]

export default function useChart(options?: ApexOptions) {
  const baseOptions: ApexOptions = useMemo(
    () => ({
      colors,

      // Chart
      chart: {
        toolbar: { show: false },
        zoom: { enabled: false },
        foreColor: '#9ca3af',
        fontFamily: 'Inter, sans-serif',
      },

      // States
      states: {
        hover: { filter: { type: 'lighten', value: 0.04 } },
        active: { filter: { type: 'darken', value: 0.88 } },
      },

      // Fill
      fill: {
        opacity: 1,
        gradient: {
          type: 'vertical',
          shadeIntensity: 0,
          opacityFrom: 0.6,
          opacityTo: 0.1,
          stops: [0, 100],
        },
      },

      // Data Labels
      dataLabels: { enabled: false },

      // Stroke
      stroke: {
        width: 3,
        curve: 'smooth',
        lineCap: 'round',
      },

      // Grid
      grid: {
        strokeDashArray: 3,
        borderColor: '#e5e7eb', // gray-300
        xaxis: { lines: { show: false } },
      },

      // Xaxis
      yaxis: {
        opposite: true,
        axisBorder: { show: false },
        axisTicks: { show: false },
      },

      // Xaxis
      xaxis: {
        axisBorder: { show: false },
        axisTicks: { show: false },
      },

      // Markers
      markers: {
        size: 0,
        strokeColors: '#f9fafb', // gray-50
      },

      // Tooltip
      tooltip: {
        x: { show: true },
      },

      // Legend
      legend: {
        show: true,
        fontSize: '13px',
        position: 'top',
        horizontalAlign: 'right',
        fontWeight: 500,
        itemMargin: { horizontal: 8 },
        labels: { colors: '#1f2937' }, // gray-800
      },

      // Plot Options
      plotOptions: {
        bar: {
          borderRadius: 3,
          columnWidth: '28%',
          borderRadiusApplication: 'end',
          borderRadiusWhenStacked: 'last',
        },

        pie: {
          donut: {
            labels: {
              show: true,
              value: {
                offsetY: 8,
                color: '#1f2937', // gray-800
                fontSize: '20px',
                fontWeight: '600',
                lineHeight: '28px',
              },
              total: {
                show: true,
                label: 'Total',
                color: '#6b7280', // gray-500
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '20px',
              },
            },
          },
        },

        radialBar: {
          track: {
            strokeWidth: '100%',
            background: '#d1d5db', // gray-400
          },
          dataLabels: {
            value: {
              offsetY: 8,
              color: '#1f2937', // gray-800
              fontSize: '20px',
              fontWeight: '600',
              lineHeight: '28px',
            },
            total: {
              show: true,
              label: 'Total',
              color: '#6b7280', // gray-500
              fontSize: '14px',
              fontWeight: '500',
              lineHeight: '20px',
            },
          },
        },

        radar: {
          polygons: {
            fill: { colors: ['transparent'] },
            strokeColors: '#e5e7eb', // gray-300
            connectorColors: '#e5e7eb', // gray-300
          },
        },

        polarArea: {
          rings: { strokeColor: '#e5e7eb' }, // gray-300
          spokes: { connectorColors: '#e5e7eb' }, // gray-300
        },
      },

      // Responsive Design
      responsive: [
        {
          breakpoint: 640, // sm
          options: { plotOptions: { bar: { columnWidth: '40%' } } },
        },
        {
          breakpoint: 768, // md
          options: { plotOptions: { bar: { columnWidth: '32%' } } },
        },
      ],
    }),
    []
  )

  return options ? deepMerge(baseOptions, options) : baseOptions
}
