'use client'

import { Controller, useFormContext } from 'react-hook-form'

import { Input, type InputProps, Typography } from '@material-tailwind/react'

type Props = InputProps & {
  name: string
  helperText?: string
}

export default function RHFTextField({
  name,
  label,
  placeholder,
  variant,
  helperText,
  type,
  disabled = false,
  className = '',
  inputMode = 'text',
}: Props) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <Input
            {...field}
            type={type}
            value={type === 'number' && field.value === 0 ? '' : field.value}
            onChange={(event) => {
              if (type === 'number') {
                field.onChange(Number(event.target.value))
              } else {
                field.onChange(event.target.value)
              }
            }}
            label={label}
            error={!!error}
            variant={variant}
            placeholder={placeholder}
            disabled={disabled}
            className={className}
            inputMode={inputMode}
          />
          {(!!error || helperText) && (
            <Typography
              variant='small'
              color='gray'
              className={`mt-2 text-left font-normal ${error ? 'text-red-500' : ''}`}
            >
              {error ? error?.message : helperText}
            </Typography>
          )}
        </div>
      )}
    />
  )
}
