'use client'

import { signIn } from 'next-auth/react'

import { useDictionary } from '@/providers'
import ButtonWithIcon from '@/components/ButtonWithIcon'
import { useState } from 'react'
import Loader from '@/components/Loader'

export default function Social() {
  const d = useDictionary('auth')
  const [loading, setLoading] = useState(false)

  const login = async (provider: string) => {
    setLoading(true)

    const searchParams = new URLSearchParams(window.location.search)
    const alertPriceId = searchParams.get('alertPriceId')
    const wishlistId = searchParams.get('wishlistId')

    const params = new URLSearchParams()
    if (alertPriceId) params.set('alertPriceId', alertPriceId)
    if (wishlistId) params.set('wishlistId', wishlistId)

    const callbackUrl = params.toString()
      ? `${window.location.origin}?${params.toString()}`
      : '/'

    await signIn(provider, { callbackUrl })
  }

  return (
    <div className='grid gap-4'>
      <Loader
        className='z-[999] bg-transparent backdrop-blur-sm'
        iconClassName='!fill-black'
        loading={loading}
      />
      <ButtonWithIcon
        onClick={() => login('google')}
        label={d('sign_in_google_btn')}
        icon='google'
      />
      <ButtonWithIcon
        onClick={() => login('facebook')}
        label={d('sign_in_fb_btn')}
        icon='facebook'
      />
    </div>
  )
}
