import Cookies from 'js-cookie'
import { LastSeenComponent } from '@/types'
import { type NextRequest, NextResponse } from 'next/server'

export function setCookieForLastSeen(
  type: string,
  slug: string,
  limit: number
) {
  const cookieItem: LastSeenComponent.Item = { type, slug }
  const currentCookie = Cookies.get('lastSeen')

  if (currentCookie == null) {
    Cookies.set('lastSeen', JSON.stringify([cookieItem]), { expires: 1 })
    return
  }

  let cookieArray = JSON.parse(currentCookie)

  if (
    cookieArray.some(
      (item: LastSeenComponent.Item) => item.type === type && item.slug === slug
    )
  ) {
    return
  }

  if (cookieArray.length >= limit) {
    cookieArray = cookieArray.slice(limit - 1)
  }
  cookieArray.push(cookieItem)
  Cookies.set('lastSeen', JSON.stringify(cookieArray), { expires: 1 })
}

export async function processCookieAction(
  request: NextRequest,
  paramName: string,
  cookiePrefix: string,
  action: (data: any) => Promise<{ success: boolean } | null>
) {
  const id = request.nextUrl.searchParams.get(paramName)
  if (!id) return null

  const cookieName = `${cookiePrefix}_${id}`
  const cookieValue = request.cookies.get(cookieName)?.value
  if (!cookieValue) return null

  try {
    const data = JSON.parse(cookieValue)
    await action(data)

    const response = NextResponse.redirect(
      new URL(data.redirectUrl, request.url),
      302
    )
    response.cookies.delete(cookieName)
    return response
  } catch (error) {
    console.error(`Error processing ${cookiePrefix} cookie:`, error)
  }
  return null
}
