import { useState, useEffect } from 'react'
import {
  getParameter,
  getOutfitData,
  getReferrerUrl,
  getSearchEngineItem,
  getProductData,
  ParseToUrlParamsString,
  MakeReplaceParamPostfix,
  MakeReplaceParamTag,
} from './utils'
import { Component } from '@/types'
import { NG_TRACKER_PAGE_VIEW } from '@/utils/http-request'
import { NG_PAGE_VIEW_ACTIVITY_TYPE, NG_WAS_SEARCH } from './constants'
import { ChannelShopUrlTagItem } from '@/types/Page'

export * from './utils'
export * from './constants'
export * from './ng-static-tracker'

export interface RequestBody {
  countryId: string
  channelPageId: string
  referral: string
  searchEngineItem: string
  url: string
  channelId: string
  uuid: string
  activityType: string

  [key: string]: string // Allow any additional properties
}

export interface TrackingExternalUrlSpecificData {
  was?: number | string
  outfitId?: number | string
  channelShopUrlTags?: Array<ChannelShopUrlTagItem> | undefined
  channelId?: number | string
}

let replaceParamPostfix: string = ''
export let CurrentPageTrackingData: string = ''

export const GetExternalLinkTrackingUrlParams = (
  data: TrackingExternalUrlSpecificData | undefined = {},
  shopId: number | string | undefined
) => {
  const { was, outfitId } = data

  const referrer = getReferrerUrl()
  const tagParams = MakeReplaceParamTag(data, shopId)

  return (
    CurrentPageTrackingData +
    (tagParams ? tagParams : '') +
    (referrer ? `&additional_data[referral]=${referrer}` : '') +
    (was ? `&additional_data[websiteActivitySource]=${was}` : '') +
    (outfitId ? `&additional_data[mainCreativeId]=${outfitId}` : '') +
    replaceParamPostfix +
    (outfitId ? `--mc${outfitId}` : '') +
    (was ? `--was${was}` : '')
  )
}

export const GetExternalLinkTrackingProductParams = (
  productId: string | number | undefined = '',
  productShopId: string | number | undefined = ''
) => {
  return (
    (productId ? `&additional_data[creativeId]=${productId}` : '') +
    (productShopId ? `&additional_data[shopId]=${productShopId}` : '')
  )
}

type Props = {
  components: Array<Component.Component>
  channelId: number
  countryId: number
  channelPageId: number
}

const buildSrc = (
  components: Array<Component.Component>,
  channelId: number,
  countryId: number,
  channelPageId: number
): string => {
  const urlParams = new URLSearchParams(window.location.search)
  const channelIdFromParams = getParameter('chn_id', 'ngChannelId', urlParams)
  const fromSearch = sessionStorage.getItem('ngSearchItemSelect')
  if (fromSearch) {
    sessionStorage.removeItem('ngSearchItemSelect')
  }
  const requestBody: RequestBody = {
    countryId: `${countryId || ''}`,
    channelPageId: `${channelPageId || ''}`,
    referral: getReferrerUrl(),
    searchEngineItem: getSearchEngineItem(),
    url: window.location.href,
    channelId: channelIdFromParams ? channelIdFromParams : `${channelId || ''}`,
    uuid: process?.env?.NEXT_PUBLIC_NG_PIXEL_UUID ?? '',
    activityType: NG_PAGE_VIEW_ACTIVITY_TYPE,
  }

  const queryString = window.location.search
  if (queryString) {
    requestBody['queryString'] = queryString.replace('?', '')
  }

  const outfitPage = getOutfitData(components)
  if (outfitPage) {
    requestBody['mainCreativeId'] = outfitPage.id
    requestBody['userId'] = outfitPage.userId
  }

  const productPage = getProductData(components)
  if (productPage) {
    requestBody['creativeId'] = productPage.productId
    requestBody['shopId'] = productPage.shopId
    if (fromSearch) {
      requestBody['internalSearchKeyword'] =
        sessionStorage.getItem('ngLastSearchTerm') ?? ''
      requestBody['internalSearchUniqueId'] =
        sessionStorage.getItem('ngLastSearchUid') ?? ''
    }
  }

  const campaignHash = getParameter(
    'campaign_hash',
    'ngCampaignHash',
    urlParams
  )
  if (campaignHash) {
    requestBody['campaignId'] = campaignHash.toString()
  }

  const postAdId = getParameter('pa_id', 'ngPostAdId', urlParams)
  if (postAdId) {
    requestBody['postAdId'] = postAdId
  }

  replaceParamPostfix = MakeReplaceParamPostfix(requestBody)
  CurrentPageTrackingData = ParseToUrlParamsString(requestBody)

  if (requestBody.internalSearchUniqueId) {
    requestBody.websiteActivitySource = NG_WAS_SEARCH
  }

  const src = new URL(NG_TRACKER_PAGE_VIEW)
  Object.keys(requestBody).forEach((key) => {
    src.searchParams.set(key, requestBody[key])
  })

  return src.toString()
}
export default function NgTracker({
  components,
  channelId,
  countryId,
  channelPageId,
}: Props) {
  const [src, setSrc] = useState('')

  useEffect(() => {
    setSrc(buildSrc(components, channelId, countryId, channelPageId))
  }, [])

  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      src={src.toString()}
      alt='pixel'
      width='1'
      height='1'
      style={{ display: 'none' }}
    />
  )
}
