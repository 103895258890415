import Image from '@/components/Image'
import { Pagination } from 'swiper/modules'
import { Media, PictureSources, Thumbnails } from '@/types'
import Slider, {
  SliderItem,
  SliderProps,
  breakpoints,
} from '@/components/Slider'

type Props = {
  medias: Array<Media>
  alt: string
  className?: string
}

export function ParentImageGallery({ medias = [], className = '' }: Props) {
  const sliderProps: SliderProps = {
    modules: [Pagination],
    slidesPerView: 1,
    navigation: {
      enabled: false,
    },
    pagination: {
      enabled: true,
      clickable: true,
    },
    enabled: true,
    breakpoints: {
      [breakpoints.lg]: {
        enabled: false,
        slidesPerView: 'auto' as const,
        pagination: {
          enabled: false,
        },
      },
    },
  }

  const singleImage = medias?.length < 2

  return (
    <Slider
      className={`relative w-full overflow-visible lg:[&>*]:grid ${singleImage ? 'lg:[&>*]:grid-cols-1' : 'lg:[&>*]:grid-cols-2'} lg:[&>*]:!transform lg:[&>*]:gap-1 ${className}`}
      {...sliderProps}
    >
      {medias?.map((item, index) => {
        const buildMediaUrl = (media?: Media): string => {
          if (media === undefined) {
            return ''
          }

          if (
            media.thumbnails === undefined ||
            !('250x250' in media.thumbnails) ||
            !('200x200' in media.thumbnails) ||
            !('150x150' in media.thumbnails)
          ) {
            return media.imageUrl
          }

          return media.thumbnails['250x250']
        }

        const buildMediaSources = (
          thumbnails?: Thumbnails
        ): PictureSources | undefined => {
          if (
            thumbnails === undefined ||
            !('250x250' in thumbnails) ||
            !('200x200' in thumbnails) ||
            !('150x150' in thumbnails)
          ) {
            return
          }

          return {
            '360': {
              src: thumbnails['150x150'],
              size: 330,
            },
            '520': {
              src: thumbnails['250x250'],
              size: 490,
            },
          }
        }

        return (
          <SliderItem key={index}>
            <div
              className={`relative ${singleImage ? 'pt-[85%] sm:pt-[65%] lg:pt-[100%]' : 'pt-[85%] sm:pt-[65%] lg:pt-[110%]'}`}
            >
              <Image
                key={index}
                className='creative-img-box absolute inset-x-0 inset-y-0 h-full w-full object-scale-down'
                src={buildMediaUrl(item)}
                data-key={item?.key}
                data-bucket={item?.title || item?.bucket}
                data-id={item?.id}
                alt={item?.title || item?.bucket}
                title={item?.title || item?.bucket}
                width={360}
                height={480}
                {...{
                  sources: buildMediaSources(item?.thumbnails),
                }}
              />
            </div>
          </SliderItem>
        )
      })}
    </Slider>
  )
}
