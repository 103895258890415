'use client'

import React, { useEffect } from 'react'
import { Typography } from '@material-tailwind/react'
import {
  OutfitDetailComponent,
  Outfit,
  Breadcrumb,
  Thumbnails,
  PictureSources,
  Media,
} from '@/types'
import { useDictionary, usePage } from '@/providers'
import Breadcrumbs from '@/components/Breadcrumbs'
import {
  OutfitImage,
  OutfitInfo,
  OutfitTabs,
  OutfitVariants,
} from '@/components/OutfitDetail'
import RelatedOutfits from '@/components/RelatedOutfits'
import { setCookieForLastSeen } from '@/utils/cookies'
import {
  GetComponentTrackingSpecificData,
  NG_WAS_OUTFIT_DETAIL_PRODUCT_BOX,
} from '@/components/NgTracker'

type Props = {
  outfit: OutfitDetailComponent.OutfitDetail
  similarOutfits: Outfit.SimilarOutfits
  breadcrumb: Array<Breadcrumb>
  lastSeenLimit: number
}

export default function OutfitDetail({
  outfit,
  similarOutfits,
  breadcrumb,
  lastSeenLimit,
}: Props) {
  const d = useDictionary('outfit_detail')
  const { channelShopUrlTags, channelId } = usePage()

  useEffect(() => {
    setCookieForLastSeen('outfit', outfit.slug, lastSeenLimit)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isInspirationOutfit = outfit.subType === Outfit.InspirationOutfitType

  const buildMediaUrl = (media?: Media): string => {
    if (media === undefined) {
      return ''
    }

    if (
      media.thumbnails === undefined ||
      !('750x750' in media.thumbnails) ||
      !('490x490' in media.thumbnails) ||
      !('330x330' in media.thumbnails)
    ) {
      return media.imageUrl
    }

    return media.thumbnails['750x750']
  }

  const buildMediaSources = (
    thumbnails?: Thumbnails
  ): PictureSources | undefined => {
    if (
      thumbnails === undefined ||
      !('750x750' in thumbnails) ||
      !('490x490' in thumbnails) ||
      !('330x330' in thumbnails)
    ) {
      return
    }

    return {
      '360': {
        src: thumbnails['330x330'],
        size: 330,
      },
      '520': {
        src: thumbnails['490x490'],
        size: 490,
      },
    }
  }

  return (
    <section className='overflow-hidden'>
      <div className='container'>
        <Breadcrumbs breadcrumbs={breadcrumb} />

        <Typography
          variant='h1'
          className='mt-0 text-left text-base font-semibold text-primary md:mt-4 md:text-lg'
        >
          {outfit.name}
        </Typography>

        <div className='flex flex-col gap-1 md:gap-8 lg:flex-row'>
          <div className='lg:basis-1/2'>
            <OutfitImage
              isInspirationOutfit={isInspirationOutfit}
              mediaUrl={buildMediaUrl(outfit.media)}
              mediaKey={outfit.media?.key}
              mediaBucket={outfit.media?.bucket}
              alt={outfit.name}
              badges={outfit.badges}
              products={outfit.products}
              outfitId={outfit.id}
              favorite={outfit.favorite}
              imageProps={{
                sources: buildMediaSources(outfit.media?.thumbnails),
              }}
            />
            <div className='hidden md:block'>
              <OutfitInfo
                code={outfit?.code}
                created={outfit?.createdAt}
                von={outfit?.creator?.username}
                userFullName={outfit?.creator?.fullName}
                userAvatarUrl={outfit?.creator?.profileImage}
                userId={outfit?.creator?.id}
                showCreator={Object.keys(outfit?.creator)?.length}
              />

              <OutfitTabs
                description={outfit.generatedDescription}
                categories={outfit?.product_category}
                brands={outfit?.brand}
                shareDescription={outfit?.generatedDescription}
                shareMediaUrl={outfit?.media?.imageUrl}
              />
            </div>
          </div>

          <div className='grid grid-cols-2 gap-4 lg:basis-2/4'>
            <OutfitVariants
              variants={outfit.products}
              isInspirationOutfit={isInspirationOutfit}
              componentTrackingSpecificData={GetComponentTrackingSpecificData(
                outfit.id,
                NG_WAS_OUTFIT_DETAIL_PRODUCT_BOX,
                channelShopUrlTags,
                channelId
              )}
            />
          </div>
          <div className='block md:hidden'>
            <OutfitInfo
              code={outfit?.code}
              created={outfit?.createdAt}
              von={outfit?.creator?.username}
              userFullName={outfit?.creator?.fullName}
              userAvatarUrl={outfit?.creator?.profileImage}
              userId={outfit?.creator?.id}
              showCreator={Object.keys(outfit?.creator)?.length}
            />

            <OutfitTabs
              description={outfit.generatedDescription}
              categories={outfit?.product_category}
              brands={outfit?.brand}
              shareDescription={outfit?.generatedDescription}
              shareMediaUrl={outfit?.media?.imageUrl}
            />
          </div>
        </div>
      </div>

      {!!(
        similarOutfits &&
        similarOutfits?.outfits &&
        similarOutfits?.outfits?.length
      ) && (
        <div className='my-2 border-t border-solid border-light-gray-color'>
          <RelatedOutfits
            title={d('related_outfits_title')}
            outfitId={outfit.id}
            categoryId={outfit.categoryId}
            count={similarOutfits.count}
            outfits={similarOutfits.outfits}
            type={Outfit.RelatedOutfits.SIMILAR}
            infiniteScroll
            limit={4}
          />
        </div>
      )}
    </section>
  )
}
